import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import DosyaYukleme from '../components/DosyaYukle';
import ProductProperties from '../components/ProductProperties';
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { Image } from 'primereact/image';

const Products = () => {
    let emptyProduct = {
        productId: -1,
        productName: '',
        productDescription: "",
        supplierId: 1,
        categoryId: null,
        unitPrice: 0,
        fixedPrice: 0,
        picture: null,
        sizeId: 1,
        colorId: 1,
        discount: 0,
        discountAvailable: false,
        discountDate: null,
        currentOrder: true,
        quantityPerUnit: 0,
        purchasePrice: 0,
        basePrice: 0,
        productDay: null,
        campaigns: null,
        stock_code: null,
        barcode: null,
        currentOrder: false,
        process_id: 0,
        status: true,
        onSiparisDurumu: false,
        onsiparisTarihi: null,
        onSiparisMetni: ""

    };

    const evetHayir = [
        {
            code: "EVET",
            descrp: "EVET"
        },
        {
            code: "HAYIR",
            descrp: "HAYIR"
        }
    ]
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productList, setProductList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [markaList, setMarkaList] = useState(null);
    const [editProducts, setEditProducts] = useState(false);
    const [kayitSonuc, setKayitSonuc] = useState(false);
    const [productID, setProductID] = useState(null);
    const [yenile, setYenile] = useState(null);
    // const [checkedIndırım, setCheckedIndırım] = useState(null);
    const [hideDiv, setHideDiv] = useState(false);


    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Category/GetCategoryList").then((response) => {
            setCategoryList(response?.data?.data?.categories)
        })
        axios.get("https://api.horecaturquoise.com/Property/GetMarka").then((response) => {
            setMarkaList(response?.data?.data)
        })
    }, [])

    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Product/GetProductList").then((response) => {
            setProductList(response?.data?.data)
        })
    }, [yenile])




    const openNew = () => {
        setProduct(emptyProduct);
        setEditProducts(false)
        setSubmitted(false);
        setProductDialog(true);
        setKayitSonuc(false)

    }

    const hideDialog = () => {
        setKayitSonuc(false)
        setEditProducts(false)
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = () => {
        let crrntordr = { ...product };
        crrntordr.currentOrder = false;
        setProduct(crrntordr)
        setSubmitted(true);
        if (product.productName !== null) {
            const addProduct = {
                productName: product.productName,
                productDescription: product.productDescription,
                supplierId: product.supplierId,
                categoryId: product.categoryId,
                unitPrice: product.unitPrice,
                fixedPrice: product.fixedPrice,
                sizeId: 1,
                colorId: 1,
                discount: product.discount,
                discountAvailable: product.discountAvailable,
                currentOrder: product.currentOrder,
                quantityPerUnit: product.quantityPerUnit,
                purchasePrice: product.purchasePrice,
                basePrice: product.basePrice,
                productDay: product.productDay,
                campaigns: 0,
                stock_code: product.stock_code,
                barcode: product.barcode,
                picture: null,
                process_id: 0,
                status: true,
                updated_at: new Date(),
                onSiparisDurumu: product.onSiparisDurumu,
                onsiparisTarihi: product.onsiparisTarihi,
                onSiparisMetni: product.onSiparisMetni


            }

            if (product.productId > -1) {
                const updateProduct = {
                    productId: product.productId,
                    productName: product.productName,
                    productDescription: product.productDescription,
                    supplierId: product.supplierId,
                    categoryId: product.categoryId,
                    unitPrice: product.unitPrice,
                    fixedPrice: product.fixedPrice,
                    sizeId: 1,
                    colorId: 1,
                    discount: product.discount,
                    discountAvailable: product.discountAvailable,
                    currentOrder: product.currentOrder,
                    quantityPerUnit: product.quantityPerUnit,
                    purchasePrice: product.purchasePrice,
                    basePrice: product.basePrice,
                    productDay: product.productDay,
                    campaigns: product.campaigns,
                    stock_code: product.stock_code,
                    barcode: product.barcode,
                    picture: null,
                    status: product.status,
                    process_id: product.process_id,
                    updated_at: new Date(),
                    discountDate: product.discountDate,
                    onSiparisDurumu: product.onSiparisDurumu,
                    onsiparisTarihi: product.onsiparisTarihi,
                    onSiparisMetni: product.onSiparisMetni


                }
                updateProduct.discount = updateProduct.discount || 0;
                updateProduct.fixedPrice = updateProduct.fixedPrice || 0;
                updateProduct.status = updateProduct.status || true;
                axios.post("https://api.horecaturquoise.com/Product/UpdateProduct", updateProduct).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Güncellendi', life: 1000 });
                    setYenile(!yenile)

                })
            }
            else {
                addProduct.discount = addProduct.discount || 0;
                addProduct.status = addProduct.status || true;
                addProduct.fixedPrice = addProduct.fixedPrice || 0;
                addProduct.productDay = addProduct.productDay || false;
                axios.post("https://api.horecaturquoise.com/Product/AddProduct", addProduct).then((response) => {
                    // setProductList(response?.data?.data.productId)
                    if (response.status == 200) {
                        setKayitSonuc(true)
                        setProductID(response?.data?.data?.productId)
                        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Eklendi,', life: 1000 });
                        setYenile(!yenile)
                        hideDialog();

                    }

                })


            }

            // setProductDialog(false);
            // setProduct(emptyProduct);
        }
    }

    const editProduct = (product) => {
        axios.get(`https://api.horecaturquoise.com/Product/GetProduct?id=${product.productId}`).then((response) => {

            setProduct({ ...response.data.data });
            setProductDialog(true);
            setProductID(product.productId)
            setKayitSonuc(true)
            setEditProducts(true)
            setHideDiv(product.onSiparisDurumu);
        })


    }






    const onCategoryChange = (e, name) => {
        let _product = { ...product };
        _product[name] = e.value;
        setProduct(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name == "basePrice") {
            let price = val.toString();
            let _product = { ...product };
            _product[`${name}`] = price;

            setProduct(_product);
        } else if (name == "productDescription") {
            let htmlText = e.htmlValue;
            let _product = { ...product }
            _product[`${name}`] = htmlText
            setProduct(_product);

        } else {
            let price = val;
            let _product = { ...product };
            _product[`${name}`] = price;

            setProduct(_product);
        }

    }

    const stokChange = (e, name) => {
        const val = e.target.value;
        let price = val;
        let _product = { ...product };
        _product[`${name}`] = price;

        setProduct(_product);


    }
    //discount ve productday seçim
    const campaignsChange = (e, rowData) => {
        let kampanyaliMi;
        if (e.value == "EVET") {
            kampanyaliMi = 1
        }
        else {
            kampanyaliMi = 0
        }

        const updateProduct = {
            productId: rowData.productId,
            productName: rowData.productName,
            productDescription: rowData.productDescription,
            supplierId: rowData.supplierId,
            categoryId: rowData.categoryId,
            unitPrice: rowData.unitPrice,
            fixedPrice: rowData.fixedPrice,
            sizeId: 1,
            colorId: 1,
            discount: rowData.discount,
            discountAvailable: rowData.discountAvailable,
            currentOrder: rowData.currentOrder,
            quantityPerUnit: rowData.quantityPerUnit,
            purchasePrice: rowData.purchasePrice,
            basePrice: rowData.basePrice,
            productDay: rowData.productDay,
            campaigns: kampanyaliMi,
            stock_code: rowData.stock_code,
            barcode: rowData.barcode,
            picture: null,
            status: rowData.status,
            process_id: rowData.process_id,
            discountDate: rowData.discountDate != null ? rowData.discountDate : new Date(),
            updated_at: new Date(),
            onSiparisDurumu: rowData.onSiparisDurumu,
            onsiparisTarihi: rowData.onsiparisTarihi != null ? rowData.onsiparisTarihi : new Date(),
            onSiparisMetni: rowData.onSiparisMetni


        }

        axios.post("https://api.horecaturquoise.com/Product/UpdateProduct", updateProduct).then((response) => {
            // setProductList(response?.data?.data)7
            if (response.status == 200) {
                setYenile(!yenile)
            }

            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kapmanya Durumu Başarılı Şekilde Güncellendi', life: 2000 });


        })


        // let secim = e.value;
        // let _durum = secim == "EVET" ? 1 : 0
        // let urunler = { ...product }
        // // urunler[`${name}`] = _durum;
        // setProduct(urunler);



    }
    const discountChange = (e, name) => {
        if (name == "campaigns") {
            let secim = e.value;
            let _durum = secim == "EVET" ? 1 : 0
            let urunler = { ...product }
            urunler[`${name}`] = _durum;
            setProduct(urunler);
        } else {
            let secim = e.value;
            let _durum = secim == "EVET" ? true : false
            let urunler = { ...product }
            urunler[`${name}`] = _durum;
            setProduct(urunler);
        }


    }

    const gununUrunu = (e, name) => {
        let secim = e.value;
        if (name == "onSiparisDurumu") {
            setHideDiv(e.value)
        }
        let urunler = { ...product }
        urunler[`${name}`] = secim;
        setProduct(urunler);

    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <Image src={rowData.images[0]?.name} preview className="shadow-1 round" style={{ borderRadius: '27px' }} width="35" />
            </>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ürün Ekle" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }





    const aciklamaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.description}
            </>
        );
    }
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    }

    const header2 = renderHeader();



    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status == true ? "Kullanımda" : "Kullanımda Değil"}
            </>
        )
    }
    const kampanyaTemplate = (rowData) => {
        return (
            <>
                <Dropdown
                    value={rowData.campaigns == 1 ? "EVET" : rowData.campaigns == 0 ? "HAYIR" : ""}
                    options={evetHayir}
                    onChange={(e) => campaignsChange(e, rowData)}
                    optionLabel="descrp"
                    optionValue='code'
                    placeholder="Kampanyalı Mı ?" />
            </>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ cursor: "pointer", background: "aliceblue", padding: "11px 0" }} onClick={() => editProduct(rowData)}>
                <i className="pi pi-pencil" />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">ÜRÜN İŞLEMLERİ</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );







    const onStatusChange = (e) => {
        let durum = e.target.value;
        let code = durum == "Kullanımda" ? true : false;
        let prdct = { ...product };
        prdct.status = code;
        setProduct(prdct)

    }
    const textEditor = (options) => {
        return <InputNumber inputId="locale-us" mode="decimal" locale="en-US" value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    }

    const onRowEditComplete1 = (e, rowData) => {
        let _products2 = { ...rowData };
        let { newData } = e;

        _products2 = newData;
        setProduct(_products2);
        axios.post("https://api.horecaturquoise.com/Product/UpdateProduct", _products2).then((response) => {
            // setProductList(response?.data?.data)7
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Güncellendi', life: 1000 });
            setYenile(!yenile)

        })
    }
    const onShipDate = (e) => {

        e.setHours(e.getHours() + 4)
        let kargoTarihi = e.toISOString();
        // updateProduct.discountDate=kargoTarihi;
        let prdct = { ...product };
        prdct.discountDate = kargoTarihi;
        setProduct(prdct)

    }

    const onSiparisTarihi = (e) => {
        e.setHours(e.getHours() + 4)
        let kargoTarihi = e.toISOString();
        // updateProduct.discountDate=kargoTarihi;
        let prdct = { ...product };
        prdct.onsiparisTarihi = kargoTarihi;
        setProduct(prdct)

    }

    function sortByViewCount(products) {
        if (products == null) {
            return products;
        }
        products.sort((a, b) => (a.productId > b.productId) ? -1 : 1);
        return products;
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <Toolbar className="mb-1" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable
                        ref={dt}
                        value={sortByViewCount(productList)}
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        selection={selectedProducts}
                        showGridlines
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        loading={productList == null ? true : false}
                        rows={9}
                        rowsPerPageOptions={[20, 25, 30]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen ürün {first} ile {last} arasında, toplam ürün sayısı : {totalRecords} "
                        globalFilter={globalFilter} size='small' emptyMessage="Ürün bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="productId" header="Ürün Id" style={{ minWidth: '5rem' }} body={(e) => e.productId}></Column>
                        <Column header="Resim" style={{ minWidth: '2rem' }} body={imageBodyTemplate}></Column>
                        <Column field="productName" header="Ürün Adı" style={{ minWidth: '20rem' }} body={(e) => e.productName}></Column>
                        <Column field="quantityPerUnit" editor={(options) => textEditor(options)} style={{ minWidth: '1rem' }} header="Stok" sortable body={(e) => e.quantityPerUnit}></Column>
                        <Column field="unitPrice" header="Satış Fiyat" editor={(options) => textEditor(options)} style={{ minWidth: '5rem' }} sortable body={(e) => e.unitPrice}></Column>
                        <Column field="purchasePrice" header="$ Karşılığı" editor={(options) => textEditor(options)} style={{ minWidth: '5rem' }} sortable body={(e) => e.purchasePrice}></Column>
                            <Column field="barcode" header="Barcode" style={{ minWidth: '5rem' }} sortable body={(e) => e.barcode} ></Column>
                        <Column field="stock_code" header="Stok Kodu" style={{ minWidth: '5rem' }} sortable body={(e) => e.stock_code}  ></Column>
                        <Column field="discount" header="İndirim Oranı" sortable body={(e) => e.discount} headerStyle={{ minWidth: '1rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column header="Güncelle" style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} ></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '1400px', height: '1000px' }} header="ÜRÜN EKLE" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="card">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-12 md:col-6 ">
                                    <span htmlFor="name">Ürün Adı Giriniz</span>
                                    <InputText
                                        id="name"
                                        maxLength={300}
                                        value={product.productName}
                                        onChange={(e) => onInputChange(e, 'productName')}
                                        required
                                        autoFocus
                                        // disabled={kayitSonuc}
                                        className={classNames({ 'p-invalid': submitted && !product.productName })} />
                                    {submitted && !product.productName && <small className="p-invalid">Ürün Adı Zorunludur.</small>}
                                </div>
                                <div className="field col-12 md:col-6">
                                        <span htmlFor="birim-fiyat">Marka Bilgisi</span>

                                        <Dropdown
                                            value={product.supplierId}
                                            options={markaList}
                                            onChange={(e) => onCategoryChange(e, "supplierId")}
                                            optionLabel="companyName"
                                            optionValue="supplierId"
                                            placeholder="Marka Seçiniz"
                                        // disabled={kayitSonuc}
                                        />
                                    </div>

                                <div className="field col-12 md:col-12">
                                    <span htmlFor="description">Açıklama Giriniz</span>
                                    <Editor
                                        style={{ height: '200px' }}
                                        maxLength={1000}
                                        value={product.productDescription}
                                        onTextChange={(e) => onInputChange(e, 'productDescription')} />
                                </div>


                                <div className="grid p-fluid">
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="birim-fiyat">Ürün Kategorisi</span>

                                        <Dropdown
                                            value={product.categoryId}
                                            options={categoryList}
                                            onChange={(e) => onCategoryChange(e, "categoryId")}
                                            optionLabel="categoryName"
                                            optionValue="categoryId"
                                            placeholder="Ürün Kategorisi Seçiniz"
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="birim-fiyat">Alış Fiyatı Giriniz</span>
                                        <InputNumber
                                            inputId="birim-fiyat"
                                            value={product.basePrice}
                                            onValueChange={(e) => onInputChange(e, "basePrice")}
                                            mode="decimal"
                                            locale="en-US"
                                            minFractionDigits={2}
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-4 md:col-3">
                                        <span htmlFor="indirim-tutar">Satış Fiyatı Giriniz(TL)</span>
                                        <InputNumber
                                            inputId="indirim-tutar"
                                            value={product.unitPrice}
                                            onValueChange={(e) => onInputChange(e, "unitPrice")}
                                            mode="decimal"
                                            locale="en-US"
                                            minFractionDigits={2}
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-4 md:col-3">
                                        <span htmlFor="indirim-tutar">Ürün Sabit Fiyatı</span>
                                        <InputNumber
                                            inputId="indirim-tutar"
                                            value={product.fixedPrice}
                                            onValueChange={(e) => onInputChange(e, "fixedPrice")}
                                            mode="decimal"
                                            locale="en-US"
                                            minFractionDigits={2}
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-4 md:col-6">
                                        <span htmlFor="indirim-tutar">Satış Fiyatı Giriniz($)</span>
                                        <InputNumber
                                            inputId="indirim-tutar"
                                            value={product.purchasePrice}
                                            onValueChange={(e) => onInputChange(e, "purchasePrice")}
                                            mode="decimal"
                                            locale="en-US"
                                            minFractionDigits={2}
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="stok">Stok Miktarı Giriniz</span>
                                        <InputNumber
                                            inputId="stok"
                                            value={product.quantityPerUnit}
                                            onValueChange={(e) => stokChange(e, "quantityPerUnit")}
                                        // disabled={kayitSonuc}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="stok">İndirim Yüzdesi Giriniz (%)</span>
                                        <InputNumber
                                            inputId="stok"
                                            value={product.discount}
                                            // disabled={kayitSonuc}
                                            onValueChange={(e) => onInputChange(e, "discount")} />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="indirim-tutar">İndirim  Geçerlilik Tarihi</span>
                                        <br />
                                        <Calendar
                                            id="shipDate"
                                            value={product?.discountDate == null ? 0 : new Date(product?.discountDate)}
                                            onChange={(e) => onShipDate(e.target.value)}
                                            dateFormat="dd.mm.yy"

                                        />


                                    </div>


                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="stock_code">Stok Kodu Giriniz</span>
                                        <InputText
                                            id="stock_code"
                                            maxLength={20}
                                            value={product.stock_code}
                                            onChange={(e) => onInputChange(e, 'stock_code')}
                                            required />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="barcode">Barcode Numarası Giriniz</span>
                                        <InputText
                                            id="barcode"
                                            maxLength={20}
                                            value={product.barcode}
                                            onChange={(e) => onInputChange(e, 'barcode')} />
                                    </div>
                                    <div className="field col-12 md:col-6"  >
                                        <span htmlFor="onSiparisDurumu">Ön Sipariş Durumu</span>
                                        <br />
                                        <InputSwitch
                                            checked={product.onSiparisDurumu}
                                            onChange={(e) => gununUrunu(e, "onSiparisDurumu")}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6" style={{ display: hideDiv ? "block" : "none" }}>
                                        <span htmlFor="onsiparisTarihi">Ön Sipariş Geçerlilik Tarihi</span>
                                        <br />
                                        <Calendar
                                            id="onsiparisTarihi"
                                            value={product?.onsiparisTarihi == null ? 0 : new Date(product?.onsiparisTarihi)}
                                            onChange={(e) => onSiparisTarihi(e.target.value)}
                                            dateFormat="dd.mm.yy"
                                        />

                                    </div>
                                    <div className="field col-12 md:col-6" style={{ display: hideDiv ? "block" : "none" }}>
                                        <span htmlFor="onSiparisMetni">Ön Sipariş Metni</span>
                                        <InputText
                                            id="onSiparisMetni"
                                            maxLength={200}
                                            value={product.onSiparisMetni}
                                            onChange={(e) => onInputChange(e, 'onSiparisMetni')} />
                                    </div>


                                    <div className="field col-12 md:col-6" style={{ display: 'none' }} >
                                        <span htmlFor="indirim-tutar">İndirimli Ürün Mü ?</span>
                                        <br />
                                        <InputSwitch
                                            checked={product.discountAvailable}
                                            onChange={(e) => gununUrunu(e, "discountAvailable")}
                                        />
                                    </div>

                                    <div className="field col-12 md:col-6">
                                        <span htmlFor="indirim-tutar">Günün Ürünü Mü ? </span>
                                        <br />
                                        <InputSwitch
                                            checked={product.productDay}
                                            onChange={(e) => gununUrunu(e, "productDay")}
                                        />
                                    </div>

                                    {editProducts ? (
                                        <>
                                            <div className="field col-12 md:col-6">
                                                <span htmlFor="indirim-tutar">Kullanım Durumu Seçiniz</span>
                                                <br />

                                                <InputSwitch
                                                    checked={product.status}
                                                    onChange={(e) => gununUrunu(e, "status")}
                                                />
                                            </div>


                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    <div className="field col-12 md:col-12">
                                        <ProductProperties product={product} />

                                    </div>


                                    <div className="field col-12 md:col-6 d-none">
                                        <span htmlFor="process_id" style={{ display: "none" }}> Shopside Numarası</span>
                                        <InputNumber style={{ display: "none" }}
                                            inputId="process_id"
                                            value={product.process_id}
                                            // disabled={kayitSonuc}
                                            onValueChange={(e) => onInputChange(e, "process_id")} />
                                    </div>

                                    {kayitSonuc !== false ? (
                                        <>
                                            <div className="field col-12 md:col-12">
                                                <span htmlFor="indirim-tutar">Resim Yükleme/Silme</span>

                                                <DosyaYukleme
                                                    product={product}
                                                    productId={productID} // ilgili kaydın id numarası
                                                    dosyaYukle={true} // dosya yükle ekranı açık olsun
                                                    listeDosyaSil={true} // dosya silme butonu açık olsun
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}




                                </div>
                            </div>
                        </div>

                    </Dialog>


                </div>
            </div>

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Products, comparisonFn)