import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import './DataTableDemo.css';
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';




const WebSlider = () => {

    let emptySlider = {
        imageId: 0,
        name: null,
        otherId: 1881,
        productId: 0,
        link: null,
        sort: 0,

    };

    const [sliderKayit, setSliderKayit] = useState(emptySlider);
    const [sliderUpdate, setSliderUpdate] = useState(emptySlider);
    const [productDialog, setProductDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deletesliderKayitDialog, setDeletesliderKayitDialog] = useState(false);
    const [product, setProduct] = useState(emptySlider);
    const [selectedsliderKayit, setSelectedsliderKayit] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [sliders, setSliders] = useState(null);
    const [edit, setEdit] = useState(false);
    const [base64, setBase64] = useState(" ");
    const [yenile, setYenile] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [silindi, setSilindi] = useState(false);
    const [visible, setVisible] = useState(false);
    const [dokumanId, setDokumanId] = useState(null);
    const [yuklemeTamam, setYuklemeTamam] = useState(false);




    const toast = useRef(null);
    const dt = useRef(null);
    const fileUploadRef = useRef(null);


    useEffect(() => {
        axios.get(`https://api.horecaturquoise.com/Product/GetImageOther?OtherId=${1881}`).then((response) => {
            setSliders(response?.data?.data)
        })
    }, [yenile])






    const openNew = () => {
        setProduct(emptySlider);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSliderKayit(emptySlider)
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeletesliderKayitDialog = () => {
        setDeletesliderKayitDialog(false);
    }


    const accept = () => {
        console.log("silindi acc", silindi);
        if (silindi !== true) {
            console.log("silindi iff", silindi);
            setSilindi(true);

        } else {

        }
        console.log("silindi acc2", silindi);

        dosyaSil();


    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'İptal Edildi', detail: 'İşleminizi İptal Ettiniz.', life: 3000 });
    }
    const onTemplateUpload = (e) => {
        console.log("e onTemplateUpload", e);
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });


        setTotalSize(_totalSize);
        onUpload();
    }
    const onTemplateSelect = (e) => {
        console.log("e onTemplateSelect", e);
        let _totalSize = totalSize;


        for (const file of e.files) {
            _totalSize += file.size;
        }

        setTotalSize(_totalSize);

    }
    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }
    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }} />
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="p-my-5" />
            </div>
        )
    }
    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const onTemplateClear = () => {
        setTotalSize(0);
    }
    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 20000000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className + " upload-header-template"} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {
                    value === 0
                        ? chooseButton
                        : <div className='pointer-events-none'> {chooseButton} </div>
                }
                {uploadButton}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 20 MB`} style={{ width: '300px', height: '20px', marginLeft: 'auto' }} /> */}
            </div>
        );
    }
    const _dosyaSil = (imageId) => {
        setBase64(null)
        let prod = { ...sliderKayit }
        prod.picture = null
        prod.pictureBase64 = null
        prod.name = null
        setSliderKayit(prod)
        axios.post(`https://api.horecaturquoise.com/Product/DeleteImage?imageId=${imageId}`).then((res) => {
        })
        setYenile(!yenile)
        toast.current.show({ severity: "success", summary: 'İşlem Başarılı', detail: 'Resim Silindi.', life: 3000 });



    }
    const dosyaSil = (imageId) => {
        console.log("silindi dosyaSil dkmnid", imageId);
        setDokumanId(imageId)
        console.log("silindi ilk", silindi);
        if (silindi !== true) {
            setVisible(true)
            setSilindi(true)
        }

        else if (silindi === true) {
            _dosyaSil(imageId);
        }



    }
    const onUpload = () => {
        toast.current.show({ severity: 'succes', summary: 'Başarılı', detail: 'Dosya Yükleme Başarılı..', life: 3000 });
    }
    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }
   

 
    const saveSlider = () => {
        setSubmitted(true);
        if (sliderKayit.link !== null && sliderKayit.link !== "") {
            let addSlider = {
                name: sliderKayit.name,
                link: sliderKayit.link,
                imageId: sliderKayit.imageId,
                otherId: sliderKayit.otherId,
                productId: sliderKayit.productId,

            }

            axios.post("https://api.horecaturquoise.com/Product/AddImage2", addSlider).then((response) => {
                // setCategoryList(response?.data?.data?.categories)         
                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kategori Eklendi', life: 3000 });
                    setProductDialog(false)
                    setYenile(!yenile)


                }
            })
        } else {

            toast.current.show({ severity: 'error', summary: 'Uyarı', detail: 'Eksik Alanları Doldurunuz', life: 3000 });


        }
        // setProducts(_products);
        // setProductDialog(false);
        // setProduct(emptyCategories);
    }



    const UpdateSlider = () => {
        if (UpdateSlider.link !== null && UpdateSlider.link !== "") {
            let UpdateSlider = {
                link: sliderUpdate.link,
                imageId: sliderUpdate.imageId,
                otherId: 1,
                productId: sliderUpdate.productId,
                sort: parseInt(sliderUpdate.sort),
                name: sliderUpdate.name,

            }
            setUpdateDialog(false);
            axios.post("https://api.horecaturquoise.com/Product/UpdateImage", UpdateSlider).then((response) => {

                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kategori Eklendi', life: 3000 });
                    setProductDialog(false)
                    setYenile(!yenile)


                }
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Uyarı', detail: 'Eksik Alanları Doldurunuz', life: 3000 });
        }
    }




    const confirmDeleteProduct = (product) => {
        axios.post(`https://api.horecaturquoise.com/Product/DeleteImage?imageId=${product.imageId}`).then((res) => {
            if (res.status === 200) {
                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Slider Silindi', life: 3000 });
                setYenile(!yenile)
            }
        })
    }
    const confirmUpdateProduct = (product) => {
        setUpdateDialog(true)
        setSliderUpdate(product);

    }



    const deleteSelectedsliderKayit = () => {
        let _sliderKayit = sliderKayit.filter(val => !selectedsliderKayit.includes(val));
        setSliderKayit(_sliderKayit);
        setDeletesliderKayitDialog(false);
        setSelectedsliderKayit(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'sliderKayit Deleted', life: 3000 });
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...sliderKayit };
        _product[`${name}`] = val;

        setSliderKayit(_product);
    }


    const onInputUpdate = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...sliderUpdate };
        _product[`${name}`] = val;
        setSliderUpdate(_product);
    }




    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Yeni Slider Ekle" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }



    const sliderTemplate = (rowData) => {
        return (

            <>
                <Image src={rowData.name} className="p-0 round" alt="Image" preview width="200" />
            </>

        )
    }
    const imageIdTemplate = (rowData) => {
        return (
            <>
                {rowData.imageId}
            </>

        )
    }
    const linkTemplate = (rowData) => {
        return (
            <>
                {rowData.link}


            </>

        )
    }

    const linkName = (rowData) => {
        return (
            <>
                {rowData.name}


            </>

        )
    }

    const hideDialogs = () => {
        setUpdateDialog(false)

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-sync" tooltip='Güncellemek Tıklayınız' tooltipOptions={{ position: 'top' }} className="p-button-rounded p-button-success  mr-2" onClick={() => confirmUpdateProduct(rowData)} />
                <Button icon="pi pi-trash" tooltip='Slider Silmek İçin Tıklayınız' tooltipOptions={{ position: 'top' }} className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Web Slider İşlemleri</h5>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" className="p-button-outlined p-button-danger" onClick={hideDialog} />
            <Button label="Kaydet"              
                icon="pi pi-check"
                className="p-button-outlined p-button-success"
                onClick={saveSlider}
                tooltip="UYARI: Seçilen Resimi Lütfen Önce Yükleyiniz." tooltipOptions={{ position: 'top' }}
            />
        </React.Fragment>
    );

    const UpdateDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" className="p-button-outlined p-button-danger" onClick={hideDialogs} />
            <Button label="Güncelle"
                icon="pi pi-refresh"
                className="p-button-outlined p-button-success"
                onClick={UpdateSlider}
            />
        </React.Fragment>
    );


    const deletesliderKayitDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletesliderKayitDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedsliderKayit} />
        </React.Fragment>
    );

    const updatemodal = (
        <>
            <Dialog visible={updateDialog} style={{ width: '900px', textAlign: 'center' }} header="Güncelleme İşlemleri" footer={UpdateDialogFooter} modal className="p-fluid" onHide={() => setUpdateDialog(false)}>
                <div className="grid p-fluid" style={{ textAlign: 'left' }}>
                    <div className="col-5 md:col-5 ">
                        <Image src={sliderUpdate?.name} className="round" alt="Image" preview width="100%" />
                    </div>
                    <div className="col-7 md:col-7 border border-primary">
                        <label htmlFor="link">Yönlendirme Adresi</label>
                        <InputText id="link" type='url' value={sliderUpdate?.link} onChange={(e) => onInputUpdate(e, 'link')} />

                        <label htmlFor="sort">Sıra Numarası</label>
                        <InputText id="sort" value={sliderUpdate?.sort} onChange={(e) => onInputUpdate(e, 'sort')}

                        />
                    </div>
                </div>
            </Dialog>
        </>
    )

    const shortSlider = (e) => {
       
        let data = e.value;       
        for (let i = 0; i < data.length; i++) {
            data[i].sort = i;
        }
        setSliders(data)
        axios.post(`https://api.horecaturquoise.com/Product/UpdateImageList`, data).then((response) => {
            setYenile(!yenile)
            toast.current.show({ severity: 'success', summary: 'Güncellendi', life: 2000 });
        });
    }



    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <Tooltip />

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                <DataTable ref={dt} value={sliders} selection={selectedsliderKayit} onSelectionChange={(e) => setSelectedsliderKayit(e.value)}
                    reorderableRows onRowReorder={shortSlider}
                    size='small'
                    dataKey="id" showGridlines paginator rows={100} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen Slider {first} ile {last} arasında, toplam  {totalRecords} kayıt bulunmaktadır"
                    header={header} responsiveLayout="scroll">
                    <Column rowReorder style={{ width: '3em' }} />
                    <Column header="Başlık" body={linkName}></Column>
                    <Column header="Slider Url" body={linkTemplate}></Column>
                    <Column header="İşlem Menüsü" body={actionBodyTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={productDialog} style={{ width: '900px' }} header="Slider İşlemleri" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="description">Slider Url</label>
                    <InputText id="description" value={sliderKayit?.link} onChange={(e) => onInputChange(e, 'link')} />
                </div>
                <div className="field">
                    <label htmlFor="description">Slider Name</label>
                    <InputText id="description" value={sliderKayit?.name} onChange={(e) => onInputChange(e, 'name')} />
                </div>
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Uyarı" modal onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {<span>Slider silinecektir. Onaylıyor musunuz ? </span>}
                </div>
            </Dialog>

            <Dialog visible={deletesliderKayitDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletesliderKayitDialogFooter} onHide={hideDeletesliderKayitDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected sliderKayit?</span>}
                </div>
            </Dialog>
            {updatemodal}
        </div>
    );
}
export default WebSlider;
