import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

import { InputText } from 'primereact/inputtext';
import axios from 'axios';



const Customers = () => {

    const [customers, setCustomers] = useState();
    const [globalFilter, setGlobalFilter] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Promocode/GetCustomeList").then((response) => {
            setCustomers(response?.data?.data)
            console.log("response2", response);
        })
    }, [])

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">MÜŞTERİ BİLGİLERİ</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Müşteri Ara..." />
            </span>
        </div>
    );
    const adBodyTemplate = (rowData) => {
        return (
            <>
                {capitalizeWords(rowData.firstName.toString().toLowerCase() + " " + rowData.lastName.toUpperCase())}
                <span style={{ color: "#ec134e",  }}>{(rowData.firmaAd && rowData.firmaAd != "-") ? ` => ${rowData.firmaAd.toString().toUpperCase()}` : ""}</span>
            </>
        );
    }
    function capitalizeWords(str) {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
    const [expandedRows, setExpandedRows] = useState(null);
    const rowExpansionTemplate = (data) => {
        var detay = []
        detay.push(data)
        return (
            <div className="p-3">
                <DataTable
                    value={detay}
                    size='small'
                    dataKey="customerId"
                    className='fontsize'
                >
                    <Column field="address1" header="Adresi" body={(e) => e.address1} headerStyle={{ width: '10%', }}></Column>
                    <Column field="city" style={{ FontSize: "smaller" }} header="İl-İlçe" body={(e) => e.city} headerStyle={{ width: '2%', }}></Column>
                    <Column field="billingAddress" style={{ FontSize: "smaller" }} header="Fatura Adresi" body={(e) => e.billingAddress} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                    <Column field="billingCity" header="İl-İlçe" body={(e) => e.city} headerStyle={{ width: '2%' }}></Column>
                    <Column field="firmaAd" header="Firma Adı" body={(e) => e.firmaAd} headerStyle={{ width: '5%' }}></Column>
                    <Column field="vergiNo" header="Vergi No" body={(e) => e.vergiNo} headerStyle={{ width: '5%' }}></Column>
                    <Column field="vergiDaire" header="Vergi Dairesi" body={(e) => e.vergiDaire} headerStyle={{ width: '5%' }}></Column>
                </DataTable>
            </div>
        );
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <DataTable
                        ref={dt}
                        showGridlines
                        value={customers}
                        dataKey="customerId"
                        paginator
                        rows={20}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}
                        size='small'
                        rowsPerPageOptions={[20, 25, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen müşteri {first} ile {last} arasında, toplam müşteri sayısı {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Müşteri bulunamamıştır." header={header} >
                        <Column expander={rowExpansionTemplate} header="Seç" headerStyle={{ width: '1%', minWidth: '1%' }} />
                        <Column field="customerId" header="No" body={(e) => e.customerId} headerStyle={{ width: '1%' }}></Column>
                        <Column field="firstName" header="Adı Soyadı" body={adBodyTemplate} headerStyle={{ width: '20%', }}></Column>
                        <Column field="phone" header="Telefonu" body={(e) => e.phone} headerStyle={{ width: '10%' }}></Column>
                        <Column field="email" header="E-Mail Adresi" body={(e) => e.email} headerStyle={{ width: '10%' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(Customers, comparisonFn);