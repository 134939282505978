
import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import App from '../App';
import './FormDemo.css';
import { classNames } from 'primereact/utils';

const Login = (props) => {

    const toast = useRef(null);
    const [kullaniciAdi, setKullaniciAdi] = useState("admin");
    const [parola, setParola] = useState();


    let kAdi = "admin";
    let sifre = "1907--";

    const girisControl = () => {
        if (kullaniciAdi == kAdi && parola == sifre) {
            props.setLogin(true)
            sessionStorage.setItem("ka", kullaniciAdi)
            sessionStorage.setItem("pr", parola)
        } else {
            toast.current.show({ severity: 'error', summary: 'Kullanıcı Adı veya Parola Yanlış !', detail: 'HATA', life: 3000 });

        }
    }



    return (
        <div className='form-demo2'>
            <div className="form-demo">
                <Toast ref={toast} />
                <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
                    <div className="flex flex-column align-items-center justify-content-center">
                        <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(rgb(101 100 100) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                            <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                <div className="text-center mb-5">
                                    <img src="assets/layout/images/logo-4.png" alt="Image" height="80" className="mb-3" />
                                    <div className="text-900 text-3xl font-medium mb-3">Hoş Geldiniz </div>
                                </div>

                                <div>
                                    <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                        Kullanıcı Adı
                                    </label>
                                    <InputText value={kullaniciAdi}  name='email1' onChange={(e) => setKullaniciAdi(e.target.value)} className="w-full mb-5" inputClassName="w-full p-3" />

                                    <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
                                       Parola
                                    </label>
                                    <Password value={parola} name='password1' onChange={(e) => setParola(e.target.value)} feedback={false}  className="w-full mb-5" inputClassName="w-full p-3" />

                                    <Button onClick={girisControl} label="Giriş Yap"  className="w-full p-3 text-xl p-button-secondary"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(Login, comparisonFn);