import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const ProductProperties = (props) => {
    const { product } = props;
    let propertiesval = {
        productID: product.productId,
        propertyValue: '',
        property: null,
        propertyID: 0,
    };
    const toast = useRef(null);
    const dt = useRef(null);
    const [productvalue, setProductvalue] = useState(null);
    const [addproperty, setAddproperty] = useState(propertiesval);
    const [propertyList, setPropertyList] = useState(propertiesval);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [yenile, setYenile] = useState(false);



    useEffect(() => {
        axios.get(`https://api.horecaturquoise.com/Property/GetPropertiesValues?ProductID=${product?.productId ?? 0}`).then((response) => {
            setProductvalue(response?.data?.data)
        })
        axios.get("https://api.horecaturquoise.com/Property/GetProperties").then((response) => {
            setPropertyList(response?.data?.data)

        })
    }, [yenile])


    const onChange = (e, name) => {
        let _product = { ...addproperty };
        _product[name] = e.value;
        setAddproperty(_product);

    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...addproperty };
        _product[`${name}`] = val;
        setAddproperty(_product);
    }





    const saveProperties = () => {

        console.log("KAYIT İŞLEMŞ", addproperty)

        if (addproperty?.propertyValue == null) {
            toast.current.show({ severity: 'Error', summary: 'Hata', detail: 'Lütfen Başlık Detayı Girin', life: 3000 });
            return;
        }
        if (addproperty.propertyID == null || addproperty.propertyID <= 0) {
            toast.current.show({ severity: 'Error', summary: 'Hata', detail: 'Lütfen Lütfen Özellik Tipi Seçin', life: 3000 });
            return;
        }
        axios.post("https://api.horecaturquoise.com/Property/SetPropertyValue", addproperty).then((response) => {
            if (response.status == 200) {
                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Özellik Başlığı  Eklendi', life: 3000 });
                setYenile(!yenile)
            }
            else{
                toast.current.show({ severity: 'Error', summary: 'Hata', detail: 'KAyıt İşlemi Başarısız', life: 3000 });
            }
        });
    }






    return (
        <div className="grid crud-demo mt-2 ">
            <Toast ref={toast} position="top-center" />
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="field col-12 md:col-5">
                        <span htmlFor="birim-fiyat">Özellik Başlığı</span>

                        <Dropdown
                            value={addproperty.propertyID}
                            options={propertyList}
                            onChange={(e) => onChange(e, "propertyID")}
                            optionLabel="propertyName"
                            optionValue="propertyID"
                            placeholder="Seçiniz"
                        />
                    </div>
                    <div className="field col-12 md:col-6 ">
                        <span htmlFor="stock_code">Özellik Detayı</span>
                        <InputText
                            id="stock_code"
                            maxLength={20}
                            value={addproperty.propertyValue}
                            onChange={(e) => onInputChange(e, 'propertyValue')}
                            required />
                    </div>
                    <div className="field col-12 md:col-1 ">
                        <span htmlFor="stock_code " style={{ color: 'white' }}>_</span>
                        <Button label="Kaydet" disabled={addproperty?.propertyValue?.length <= 0 || addproperty?.propertyID <= 0} onClick={saveProperties} className="p-button-success" />
                    </div>
                </div>
                <DataTable
                    ref={dt}
                    showGridlines
                    value={productvalue}
                    dataKey="productID"
                    paginator
                    size='small'
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen Özellik {first} ile {last} arasında, toplam özellik sayısı {totalRecords} "
                    globalFilter={globalFilter} emptyMessage="Tanımlanmıl özellik bulunamamıştır." header="Tanımlı Özelikler" responsiveLayout="scroll">
                    <Column field="property.propertyName" header="Başlık" body={(e) => e.property.propertyName} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="propertyValue" header="Özellik" body={(e) => e.propertyValue} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                </DataTable>
            </div>
        </div>
    );
}

export default ProductProperties;
