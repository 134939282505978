import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import DosyaYukleme from '../components/DosyaYukle';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Image } from 'primereact/image';



import axios from 'axios';



const Category = () => {
    let emptyCategories = {
        categoryId: null,
        categoryName: '',
        picture: null,
        description: '',
        pictureBase64: null,
        active: null,
    };
    let emptyAltCategories = {
        categoryId: null,
        parent_id: null,
        categoryName: '',
        picture: null,
        description: '',
        pictureBase64: null,
        active: null,
    };

    const kullanimDurumu = [
        {
            code: "Kullanımda",
            name: "Kullanımda"
        },
        {
            code: "Kullanımda Değil",
            name: "Kullanımda Değil"
        }
    ]

    const categry = {
        code: null,
        name: null
    }

    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyCategories);
    const [altCategory, setAltCategory] = useState(emptyAltCategories);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [categoryList, setCategoryList] = useState();
    const [base64, setBase64] = useState(" ");
    const [editCategory, setEditCategory] = useState(false);
    const [altCate, setAltCate] = useState(false);
    const [yenile, setYenile] = useState(false);
    const [kategoriler, setKategoriler] = useState([categry]);
    const [kategorilerUst, setKategorilerUst] = useState([]);
    const [kategorilerAlt, setKategorilerAlt] = useState([]);
    const [listeAltKategori, setListeAltKategori] = useState(false);
    const [kayitSonuc, setKayitSonuc] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [visible, setVisible] = useState(false);
    const [silindi, setSilindi] = useState(false);
    const [dosyaListesi, setDosyaListesi] = React.useState([]);
    const [dokumanId, setDokumanId] = useState(null);
    const [barYukleniyor, setBarYukleniyor] = useState(false);
    const [numberofTry, setNumberofTry] = useState(0)




    console.log("base64", base64);
    const toast = useRef(null);
    const dt = useRef(null);

    console.log("prod22", product);

    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Category/GetCategoryList").then((response) => {
            setCategoryList(response?.data?.data?.categories)

        })
    }, [yenile])

    useEffect(() => {

        if (categoryList !== undefined) {
            categoryList.map((kate) => {
                let kategori = { ...kategoriler, code: kate.categoryId, name: kate.categoryName }
                kategoriler.push(kategori)
            })

        }


    }, [categoryList])

    useEffect(() => {

        if (categoryList !== undefined) {
            let kategoriListe = categoryList.filter((categry) => categry.parent_id == 0)
            console.log("kategoriListe2", kategoriListe);
            kategoriListe.map((kate) => {
                kategorilerUst.push(kate)
            })
        }
    }, [categoryList])
    useEffect(() => {

        if (categoryList !== undefined) {
            let kategoriListe = categoryList.filter((categry) => categry.parent_id !== 0)
            console.log("kategoriListe22", kategoriListe);
            kategoriListe.map((kate) => {
                kategorilerAlt.push(kate)
            })
        }
    }, [categoryList])



    console.log("categoryList", categoryList);
    console.log("kategorilerUst", kategorilerUst);
    console.log("kategoriler2", kategoriler);
    const openNew = () => {
        setProduct(emptyCategories);
        setSubmitted(false);
        setProductDialog(true);
        setAltCate(false)
    }
    const openAltNew = () => {
        setAltCategory(emptyAltCategories);
        setSubmitted(false);
        setProductDialog(true);
        setAltCate(true)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setAltCate(false)
    }

    function kisaltMetin(metin, uzunlukSiniri = 50) {
        if (metin.length <= uzunlukSiniri) {
            return metin;
        }
        return metin.slice(0, uzunlukSiniri) + "...";
    }


    const _dosyaListele = () => {

        setDosyaListesi(product.picture);


    }
    const saveCategory = () => {
        setSubmitted(true);
        console.log("category id ne ", product);
        if (product.categoryName !== null && product.description !== null && product.categoryId == -1 || product.categoryId == null) {
            console.log("geldi add");
            let addCategory = {
                categoryName: product.categoryName,
                description: product.description,
                parent_id: product.parent_id,
                pictureBase64: base64,
                active: true,
                picture: product.picture,
            }

            axios.post("https://api.horecaturquoise.com/Category/AddCategory", addCategory).then((response) => {
                // setCategoryList(response?.data?.data?.categories)
                console.log("res12r4", response);
                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kategori Eklendi', life: 3000 });
                    setAltCate(false)
                    setProductDialog(false)
                    setKayitSonuc(true)
                    setYenile(!yenile)


                }
            })
        } else {

            let updateCategory = {
                categoryId: product.categoryId,
                categoryName: product.categoryName,
                description: product.description,
                pictureBase64: base64,
                active: product.active,
                parent_id: product.parent_id,
                picture: product.picture,
            }

            axios.post("https://api.horecaturquoise.com/Category/UpadateCategory", updateCategory).then((response) => {
                // setCategoryList(response?.data?.data?.categories)
              
                if (response.status == 200) {
                    setAltCate(false)
                    setProductDialog(false)
                    setYenile(!yenile)



                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kategori Güncellendi', life: 3000 });
                }
            })

        }
        // setProducts(_products);
        // setProductDialog(false);
        // setProduct(emptyCategories);
    }

    // alt kategori save


    const editCategory1 = (product) => {
        console.log("prod1", product);
        setProduct({ ...product });
        setProductDialog(true);
        setEditCategory(true)

    }






    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }
    const onInputAltChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...altCategory };
        _product[`${name}`] = val;

        setAltCategory(_product);
    }
    const onParentId = (e) => {
        console.log("e.onparent", e);
        const val = (e.target && e.target.value) || '';
        let _alt = { ...product };
        _alt.parent_id = val

        setProduct(_alt);
    }

    console.log("alt243", altCategory);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Kategori Ekle" icon="pi pi-plus" className=" p-button-raised p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
                <div className="my-2">
                    {/* <Button label="Alt Kategori Ekle" icon="pi pi-plus" className="p-button-raised p-button-success p-button-text mr-2" onClick={openAltNew} /> */}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>



            </React.Fragment>
        )
    }


    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.categoryId}
            </>
        );
    }
    const ustIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.parent_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.categoryName}
            </>
        );
    }





    const aciklamaTemplate = (rowData) => {
        return (
            <>

                {kisaltMetin(rowData.description, 70)}
            </>
        );
    }



    const statusBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Status</span> */}
                {rowData.active == true ? "Kullanımda" : "Kullanımda Değil"}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" label='Güncelle' className="p-button-raised p-button-success mr-2" onClick={() => editCategory1(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">KATEGORİ İŞLEMLERİ</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Kategori Ara..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" disabled={!base64 && product?.picture == null} icon="pi pi-check" className="p-button-text" onClick={saveCategory} />
        </>
    );





    const fileUploadRef = useRef(null);

    // const onChangeActive1 = (e) => {
    //     console.log("e2", e);
    //     let durum = false;
    //     e == "Kullanımda" ? durum = true : durum = false;
    //     let categoryState = { ...product }
    //     categoryState.active = durum;
    //     setProduct(categoryState)
    // }
    const onChangeActive = (e, name) => {
        console.log("e232", e);
        let secim = e.value;
        let categoryState = { ...product }
        categoryState[`${name}`] = secim;
        setProduct(categoryState);

    }

    console.log("product4", product);

    const customBase64Uploaders = async (event) => {
        console.log("eventtt1", event);
        // convert file to base64 encoded 
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            // setBase64(reader.result);
        }
    }
    const accept = () => {
        console.log("silindi acc", silindi);
        if (silindi !== true) {
            console.log("silindi iff", silindi);
            setSilindi(true);

        } else {

        }
        console.log("silindi acc2", silindi);

        dosyaSil();


    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'İptal Edildi', detail: 'İşleminizi İptal Ettiniz.', life: 3000 });
    }

    const onTemplateUpload = (e) => {
        console.log("e onTemplateUpload", e);
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });


        setTotalSize(_totalSize);
        onUpload();
    }
    const onTemplateSelect = (e) => {
        console.log("e onTemplateSelect", e);
        let _totalSize = totalSize;


        for (const file of e.files) {
            _totalSize += file.size;
        }

        setTotalSize(_totalSize);

    }
    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }
    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }} />
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="p-my-5" />
            </div>
        )
    }
    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const onTemplateClear = () => {
        setTotalSize(0);
    }
    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 20000000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className + " upload-header-template"} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {
                    value === 0
                        ? chooseButton
                        : <div className='pointer-events-none'> {chooseButton} </div>
                }
                {uploadButton}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 20 MB`} style={{ width: '300px', height: '20px', marginLeft: 'auto' }} /> */}
            </div>
        );
    }
    const _dosyaSil = () => {
        setBase64(null)
        let prod = { ...product }
        prod.picture = null
        prod.pictureBase64 = null
        setProduct(prod)
        toast.current.show({ severity: "success", summary: 'İşlem Başarılı', detail: 'Resim Silindi.', life: 3000 });


    }
    console.log("product20", product);
    const dosyaSil = (imageId) => {
        console.log("silindi dosyaSil dkmnid", imageId);
        setDokumanId(imageId)
        console.log("silindi ilk", silindi);
        if (silindi !== true) {
            setVisible(true)
            setSilindi(true)
        }

        else if (silindi === true) {
            console.log("silindi else if", silindi);
            console.log("silindi setDokumanId", dokumanId);
            _dosyaSil(imageId);
        }



    }
    const onUpload = () => {
        toast.current.show({ severity: 'succes', summary: 'Başarılı', detail: 'Dosya Yükleme Başarılı..', life: 3000 });
    }
    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }
    const _dosyaYukle = (event) => {

        const [file] = event.files;
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
            console.log("e onload", e);
            let fileBase64 = await e.target.result;
            console.log("filebase53", fileBase64);
            setBase64(fileBase64)
            toast.current.show({ severity: "success", summary: "Resim değeri tutuldu, kayıt için KAYDET tuşuna basınız.", life: 2000 });


        };
        // fileUploadRef.current.clear();

        fileReader.readAsDataURL(file);


    };
    console.log("base64", base64);
    console.log("213", product);
    const dosyaYukleme = () => {
        return (
            <>
                <Toast ref={toast} position="top-center" />
                {



                    <div className='dosya-yukleme' style={{ marginTop: "1rem" }}>

                        <Tooltip style={{ witdh: "100rem" }} target=".custom-choose-btn" content="Dosya Seç" position="bottom" className='blue-tooltip' disabled={totalSize > 0} />
                        <Tooltip target=".custom-upload-btn" content="Dosya Yükle" position="bottom" className='blue-tooltip' />
                        <Tooltip target=".custom-cancel-btn" content="Dosya Temizle" position="bottom" className='blue-tooltip' />


                        {/* <InputText
                                id="dosya"
                                value={dosyaTanimi}
                                onChange={(e) => setDosyaTanimi(e.target.value)}
                                style={{ width: "250px" }}
                                placeholder='Dosyaya Bir İsim Veriniz.'
                                maxLength="200"
                                tooltip="UYARI : En Fazla 200 Karakter Girebilirsiniz" tooltipOptions={{ position: 'top' }}
                            /> */}

                        <div className="p-field p-col-12 p-md-12">
                            <span className="p-float-label">
                                <ConfirmDialog
                                    visible={visible}
                                    onHide={() => setVisible(false)}
                                    message="Dosya Silme İşlemi Yapmak Üzeresiniz.Devam Etmek İstiyor Musunuz?"
                                    header="Dikkat"
                                    icon="pi pi-exclamation-triangle"
                                    accept={accept}
                                    reject={reject}
                                    acceptClassName="p-button-raised p-button-primary"
                                    rejectClassName="p-button-raised p-button-danger"
                                    acceptLabel="Evet"
                                    rejectLabel="Hayır"
                                    acceptIcon="pi pi-check"
                                    rejectIcon="pi pi-times"
                                />
                            </span>
                        </div>


                        <FileUpload
                            ref={fileUploadRef}
                            name="demo"
                            uploadHandler={_dosyaYukle}
                            maxFileSize={20000000}
                            accept=".png,.jpeg,.jpg,"
                            onUpload={onTemplateUpload}
                            onSelect={onTemplateSelect}
                            onError={onTemplateClear}
                            onClear={onTemplateClear}
                            headerTemplate={headerTemplate}
                            itemTemplate={itemTemplate}
                            emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions}
                            uploadOptions={uploadOptions}
                            cancelOptions={cancelOptions}
                            customUpload={true}
                        />

                    </div>
                }

                {
                    product.picture !== null ? (
                        <>
                            <div className="card">


                                <Button
                                    tooltip="Resimi Silmek İçin Tıklayın."
                                    tooltipOptions={{ position: 'top' }}
                                    onClick={() => _dosyaSil("dosya.imageId")}
                                    icon="pi pi-times"
                                    className="p-button-rounded p-button-danger" />




                                <span

                                    // onClick={() => getFileDownloadUrl(dosya.dkmnid, dosya.dsyad, dosya.contentType)}
                                    style={{ flexGrow: 0, cursor: 'pointer', padding: '5px 0' }}>
                                    <img src={product.picture} className="shadow-2" width="150" />
                                </span>





                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )
                }


            </>

        );
    }
    console.log("base6444", base64);
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Image</span> */}
                <Image src={rowData.picture} className="shadow-1 round" style={{ borderRadius: '27px' }} preview  width="50" />
            </>
        )
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".custom-choose-btn" content="Dosya Seç" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Dosya Yükle" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Dosya Sil" position="bottom" />
                    {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                    <DataTable
                        ref={dt}
                        showGridlines
                        value={categoryList}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="categoryId"
                        paginator
                        size='small'
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen kategori {first} ile {last} arasında, toplam kategori sayısı {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Kategori bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="categoryId" header="Kategori Id" body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column header="Resim" style={{ minWidth: '5rem' }} body={imageBodyTemplate}></Column>
                        <Column field="parentId" header="Üst Kategori Id" body={ustIdBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="categoryName" header="Kategori Adı" body={nameBodyTemplate} headerStyle={{ width: '21%', minWidth: '10rem' }}></Column>
                        <Column field="description" header="Açıklama" body={aciklamaTemplate} headerStyle={{ width: '21%', minWidth: '8rem' }}></Column>
                        <Column field="active" header="Kullanım Durumu" body={statusBodyTemplate} headerStyle={{ width: '21%', minWidth: '10rem' }}></Column>
                        <Column header="İşlem Menüsü" body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
                    </DataTable>


                    <Dialog visible={productDialog} style={{ width: '950px' }} header="KATEGORİ DÜZENLE" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}

                        <div className="field">
                            <label htmlFor="parentId">Üst Kategori Seçiniz</label>
                            <Dropdown
                                id="parentId"
                                options={kategoriler}
                                value={product.parent_id}
                                onChange={(e) => onParentId(e)}
                                optionLabel='name'
                                optionValue='code'
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Kategori Adı</label>
                            <InputText id="name" value={product.categoryName} onChange={(e) => onInputChange(e, 'categoryName')} required className={classNames({ 'p-invalid': submitted && !product.categoryName })} />
                            {submitted && !product.name && <small className="p-invalid">Kategori Adı Zorunludur.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Kategori Açıklaması</label>
                            <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required />
                            {submitted && !product.description && <small className="p-invalid">Kategori Açıklaması Zorunludur.</small>}

                        </div>

                        <div className="field col-12 md:col-12">
                            <span htmlFor="indirim-tutar">Resim Yükleme/Silme</span>

                            {dosyaYukleme()}
                        </div>


                        {
                            editCategory ? (
                                <>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="active">KULLANIM DURUMU</label>
                                            <br />
                                            <InputSwitch
                                                checked={product?.active}
                                                onChange={(e) => onChangeActive(e, "active")}
                                            />
                                        </div>

                                    </div>
                                </>
                            ) : (
                                <>
                                </>
                            )
                        }


                    </Dialog>


                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Category, comparisonFn);