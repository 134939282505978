import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import './DataTableDemo.css';
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import SiparisFisi from "../components/SiparisFisi";
import { useReactToPrint } from "react-to-print";
import Barcode from 'react-barcode';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import Pdf from "react-to-pdf";
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { SpeedDial } from 'primereact/speeddial';

const OrderPages = () => {

    const customerInfo = {
        orderedId: "",
        customerId: "",
        orderNumber: "",
        orderDate: "",
        shipped: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        billingAddress: "",
        email: ""
    }
    const orderInfo = {
        orderId: "",
        orderNumber: "",
        orderDate: "",
        shipDate: "",
        shipStatus: "",
        shipperId: "",
        freight: "",
        salesTax: "",
        fulFilled: "",
        deleted: "",
        paymentDate: "",
        paid: "",
        transactStatus: "",
        errLoc: "",
        errMsg: "",
        shipNumber: "",
        installments: 0

    }
    const kargoBilgileriEmpty = {
        integrationCode: "",
        tradingWaybillNumber: "",
        invoiceNumber: "",
        receiverName: "",
        receiverAddress: "",
        receiverPhone1: "",
        receiverCityName: "",
        receiverTownName: "",
        pieceCount: null,
        weight: "",
        volumetricWeight: "",
        pieceDetails: []

    }

    const egcTeknik = {
        name: "EGC Teknoloji",
        address: "İnönü, 1754 sk. No:7  Ostim OSB/Yenimahalle ANKARA",
        phone: "0551 975 73 93",
        shipmentType: "Gönderici Ödemeli"

    }
    const [customer, setCustomer] = useState(customerInfo);
    const [order, setOrder] = useState(orderInfo);
    const [kargo, setKargo] = useState(kargoBilgileriEmpty);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const isMounted = useRef(false);
    const [orders, setOrders] = useState(null);
    const [ordersDialog, setOrdersDialog] = useState(false);
    const [customerDialog, setCustomerDialog] = useState(false);
    const [kargoDialog, setKargoDialog] = useState(false);
    const [ordersPrintDialog, setOrdersPrintDialog] = useState(false);
    const [seciliSiparis, setSeciliSiparis] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleKargo, setVisibleKargo] = useState(false);
    const [visibleKargoSil, setVisibleKargoSil] = useState(false);
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [listeYenile, setListeYenile] = useState(false);
    const [kargoVrlmsMi, setKargoVrlmsMi] = useState(false);
    const [barcodeNumber, setBarcodeNumber] = useState(null);
    const [parcaSayisi, setParcaSayisi] = useState(0);
    const [parcalar, setParcalar] = useState([]);
    const [parcaBilgiGiris, setParcaBilgiGiris] = useState(false);
    const [geriDon, setGeriDon] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [kayitVar, setKayitVar] = useState(false);
    const [etiket, setEtiket] = useState(false);
    const [visibleEtiket, setVisibleEtiket] = useState(false);
    const [listIndex, setListIndex] = useState(null);
    const [ofisTeslim, setOfisTeslim] = useState(false);
    const [kargoSilDurum, setKargoSilDurum] = useState(false);
    const [secilenEtiket, setSecilenEtiket] = useState(null);
    const [secilenEtiketIndex, setSecilenEtiketIndex] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })



    const sipStat = [
        { name: 'Onaylandı', code: 'Onaylandı' },
        { name: 'Hazırlanıyor', code: 'Hazırlanıyor' },
        { name: 'Kargolandı', code: 'Kargolandı' },
        { name: 'Teslim Edildi', code: 'Teslim Edildi' },
        { name: 'Sipariş İptal', code: 'Sipariş İptal' },
        { name: 'İade', code: 'İade' },
        { name: 'Diğer', code: 'Diğer' }
    ];

    let todayDate = new Date().toISOString().slice(0, 10);



    useEffect(() => {
        axios.get(`https://api.horecaturquoise.com/Orders/GetOrderList?now=${todayDate}`).then((response) => {
            setOrders(response?.data?.data)

        })
    }, [listeYenile])
    useEffect(() => {
        let office = orders?.filter((order) => order.deliveredOffice == true)
        if (office !== null || undefined) {
            setOfisTeslim(true)

        }
    }, [orders])





    useEffect(() => {
        if (parcaSayisi !== null || " ") {
            for (let i = 0; i < parcaSayisi; i++) {
                let randomSayi = Math.random(1, 100);
                parcalar?.push({ randomSayi })
            }
        } else {

        }



    }, [parcaSayisi])




    addLocale('en', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        // dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        // monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Gün',
        // clear: 'Limpiar'
    });


    const orderDate = (rowData) => {
        return (
            <>
                {formatDate(new Date(rowData.orderDate))}
                {/* {rowData.orderDate.split("T")[0]} */}

            </>
        );
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return (
            [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('-') +
            ' ' +
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes()),
            ].join(':')
        );
    }
    const shipDate = (rowData) => {
        return (
            <>
                {/* {rowData.shipDate == null ? "Kargoya Verilmedi." : rowData.shipDate.split("T")[0]} */}
                {rowData.shipDate == null ? "Kargoya Verilmedi." : new Date(rowData.shipDate).toLocaleString().split(' ')[0]}            </>
        );
    }
    const ofisTeslimBody = (rowData) => {
        return (
            <>
                {rowData.deliveredOffice == true ?
                    <Tag severity="danger" value="Evet"></Tag>
                    :
                    <Tag value="Hayır"></Tag>

                }
            </>
        );
    }
    const paidBody = (rowData) => {
        return (
            <>
                {rowData.installments > 0 ?
                    <>
                        {rowData.paid + " TL  "}
                        <br></br>
                        <Tag lassName="mt-1" icon="pi pi-check" severity="info" value={rowData.installments + " Taksit"} ></Tag>
                    </> : rowData.paid + " TL"}
            </>
        );
    }
    const transactStatusBody = (rowData) => {
        return (
            <>
                {rowData.transactStatus == null ? "Veri Yok" : rowData.transactStatus}
            </>
        );
    }
    const errmsgBody = (rowData) => {
        return (
            <>
                {rowData.errMsg == null ? "Banka Mesajı Yok" : rowData.errMsg}
            </>
        );
    }
    const priceBody = (rowData) => {
        return (

            <>
                {rowData.discount > 0 ?
                    <span style={{ fontStyle: 'italic', color: 'green' }}>{rowData.price + " ( %" + rowData.discount + ")  = " + Math.round((rowData.price - (rowData.price * rowData.discount / 100)) * 100) / 100 + " TL"}</span>
                    : rowData.price + " TL"}

            </>
        );
    }
    const TotalBody = (rowData) => {
        return (
            <span style={{ fontWeight: 'bold' }}>
                {rowData.total + " TL"}
            </span>
        );
    }
    const shipStatus = (rowData) => {
        return (
            <>
                {rowData.shipStatus == 1 ? <Tag severity="warning" value="Onaylandı" rounded></Tag> :
                    rowData.shipStatus == 2 ? <Tag severity="warning" value="Hazırlanıyor" rounded></Tag> :
                        rowData.shipStatus == 3 ? <Tag severity="success" value="Kargolandı"></Tag> :
                            rowData.shipStatus == 4 ? <Tag value="Teslim Edildi"></Tag> :
                                rowData.shipStatus == 5 ? <Tag severity="danger" value="Sipariş İptal" rounded></Tag> :
                                    rowData.shipStatus == 6 ? <Tag severity="danger" value="İade" rounded></Tag> :
                                        <Tag severity="info" value="Diğer" rounded></Tag>
                }
            </>
        );
    }





    const isActive = (rowData) => {
        return (
            <>
                {
                    rowData.installments == 0 ?
                        <Message severity="success" text="Garanti" /> :
                        rowData.isActive == true ? <Message severity="success" text="paytr" /> :
                            <Button label="Bekliyor" className="p-button-warning p-button-outlined  " tooltip={rowData.errMsg} tooltipOptions={{ className: 'purple-tooltip', position: 'top' }} />
                }

            </>
        );
    }

    const editCustomer = (rowData) => {
        setCustomer(rowData?.listOrderDetail[0]?.ordered)
        setCustomerDialog(true)
    }
    const editOrder = (rowData) => {
        if (rowData.shipDate !== null) {
            setKargoVrlmsMi(true)
        }
        setOrder(rowData)
        setOrdersDialog(true)
    }
    const siparisFisiFunc = (rowData) => {
        setSeciliSiparis(rowData)
        setOrdersPrintDialog(true)
    }
    const kargoBilgi = (rowData) => {


        let todayDate = new Date(rowData.orderDate).toISOString().slice(0, 10);
        let brcdTrh = todayDate.split("-").join("");
        let phone = rowData?.listOrderDetail[0]?.ordered?.phone;
        let city = ""; // rowData?.listOrderDetail[0]?.ordered?.province
        let town = "" // rowData?.listOrderDetail[0]?.ordered?.county
        let adres = rowData?.listOrderDetail[0]?.ordered?.address;
        let rcvrNme = rowData?.listOrderDetail[0]?.ordered?.firstName + " " + rowData?.listOrderDetail[0]?.ordered?.lastName;
        let brcdNo = brcdTrh + rowData.orderNumber
        let kod = 202211212298612

        let kargoBilgisi = {
            ...kargo, integrationCode: brcdNo,
            receiverPhone1: phone,
            receiverName: rcvrNme,
            receiverAddress: adres,
            receiverCityName: city,
            receiverTownName: town,

        }
        if (brcdNo !== null) {
            axios.get(`https://api.horecaturquoise.com/Kargo/GetorderIntegraionCode?IntegrationCode=${brcdNo}`).then((res) => {
                if (res?.data?.data?.[0].integrationCode == brcdNo && res?.data?.data?.[0].specialField3 == null) {
                    setKargo(res?.data?.data?.[0])
                    setParcaSayisi(res?.data?.data?.[0].pieceCount)
                    setKayitVar(true)
                    setKargoSilDurum(true)

                } else {
                    setKargo(kargoBilgisi)
                    setKargoSilDurum(false)


                }
            })
        }
        setBarcodeNumber(brcdNo)
        // setSeciliSiparis(rowData)
        setKargoDialog(true)
    }

    const onHide = () => {
        setCustomerDialog(false);
    }

    const onHideKargoConfirm = () => {
        setVisibleKargo(false);
    }
    const onHideKargo = () => {
        setKargoDialog(false);
        setKargo(kargoBilgileriEmpty)
        setParcaBilgiGiris(false)
        setSubmitted(false)
        setKargo(kargoBilgileriEmpty)
        setParcaSayisi(null)
        setParcalar([])


    }
    const kargoSil = () => {
        axios.get(`https://api.horecaturquoise.com/Kargo/SetOrderDelete?integrationCode=${kargo.integrationCode}`).then((res) => {
            toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kargo Bilgisi Silindi', life: 2000 });


        })


        setKargoDialog(false);
        setKargo(kargoBilgileriEmpty)
        setKayitVar(false)
        setSubmitted(false)

    }

    const onHideOrdered = () => {
        setOrdersDialog(false);
        setKargoVrlmsMi(false)
        setGeriDon(true)
        setParcaSayisi(null)
        setParcalar([])

    }
    const onHideEtiket = () => {
        setVisibleEtiket(false)
    }
    const onHideSipFis = () => {
        setOrdersPrintDialog(false);
    }
    const onShipStats = (e) => {
        let durum = e.target.value;
        let code = durum == "Onaylandı" ? 1 : durum == "Hazırlanıyor" ? 2
            : durum == "Kargolandı" ? 3 : durum == "Teslim Edildi" ? 4
                : durum == "Sipariş İptal" ? 5 : durum == "İade" ? 6 : 7;
        let orderss = { ...order };
        orderss.shipStatus = code;
        setOrder(orderss)

    }
    var rowselectData = null;
    const items = [
        {
            label: 'Müşteri Bilgileri',
            icon: 'pi pi-user',
            key: "1",
            command: (e) => {
                editCustomer(rowselectData)
            }
        },
        {
            label: 'Sipariş Fişi',
            icon: 'pi pi-shopping-bag',
            key: "1",
            command: (e) => {
                editOrder(rowselectData)
            }
        },
        {
            label: 'Kargo Girişi',
            icon: 'pi pi-car',
            key: "1",
            command: (e) => {
                kargoBilgi(rowselectData)
            }
        },
        {
            label: 'Sipariş Fişi',
            icon: 'pi pi-shopping-bag',
            key: "1",
            command: (e) => {
                siparisFisiFunc(rowselectData)
            }
        },
    ];

    const onClickButtons = (data) => {
        rowselectData = data;
        console.log(rowselectData);
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>

                <div style={{ height: '0px' }} className="flex align-items-center justify-content-center">
                    <SpeedDial onClick={() => onClickButtons(rowData)} showIcon="pi pi-window-maximize" hideIcon="pi pi-times" buttonClassName="p-button-outlined p-button-danger" model={items} radius={60} type="semi-circle" />
                </div>
                {/*
                <Button
                    icon="pi pi-user"
                    className="p-button-rounded p-button-primary mr-2"
                    onClick={() => editCustomer(rowData)}
                    tooltip="Müşteri Bilgileri" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                />
                 <Button
                    icon="pi pi-shopping-bag"
                    className="p-button-rounded p-button-warning mr-2"
                    onClick={() => editOrder(rowData)}
                    tooltip="Sipariş Bilgileri" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }} />
                {!rowData.deliveredOffice ?
                    <Button
                        icon="pi pi-car"
                        className="p-button-rounded p-button-danger mr-2"
                        onClick={() => kargoBilgi(rowData)}
                        disabled={rowData.deliveredOffice}
                        tooltip="Kargo Bilgileri" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }} /> : null}
                <Button
                    icon="pi pi-print"
                    className="p-button-rounded p-button-success mr-2"
                    onClick={() => siparisFisiFunc(rowData)}
                    tooltip="Sipariş Fişi" tooltipOptions={{ className: 'blue-tooltip', position: 'top' }} /> */}
            </React.Fragment>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    }


    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Image</span> */}
                <img src={rowData.product.picture} width="15" />
            </>
        )
    }

    const rowExpansionTemplate = (data) => {

        return (

            <div className="orders-subtable">
            
            {data.proCodePrice > 0 ?
                    <Message severity="success" className='mb-1 width100'  text={'% '+data.proCodePrice + ' Promosyon Kodu Uygulandı'} />

                    : <></>}
                <DataTable showGridlines value={data.listOrderDetail} responsiveLayout="scroll">
                    <Column field="productId" header="Ürün Id" body={(e) => e.productId} sortable></Column>
                    <Column field="productName" header="Ürün Adı" body={(e) => e.product.productName} ></Column>
                    <Column field="stock_code" header="Stok Kodu" body={(e) => e.product.stock_code} ></Column>
                    <Column field="quantity" header="Fiyat" body={priceBody} ></Column>
                    <Column field="quantity" header="Adet" body={(e) => e.quantity} ></Column>
                    <Column field="quantity" header="Toplam" body={TotalBody} ></Column>
                </DataTable>
              

            </div>
        );
    }

    const header = (
        <div className="table-header-container">
            <h5>SİPARİŞ İŞLEMLERİ</h5>
        </div>
    );

    const customerInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

        let _customer = { ...customer };
        _customer[`${name}`] = val;

        setCustomer(_customer);
    }
    const kargoInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

        let _kargo = { ...kargo };
        _kargo[`${name}`] = val;

        setKargo(_kargo);
        if (name == "pieceCount") {
            setParcalar([])
            let prc = parseInt(val)
            setParcaSayisi(prc)
        }
    }
    const parcaInputChange = (e, name, index) => {
        const val = (e.target && e.target.value) || '';
        // if(name == "barcodeNumber"){
        //     let _kargoB = { ...kargo };
        //     let newBrcd = kargo.integrationCode+1+index;
        //     kargo.pieceDetails[index][`${name}`] = newBrcd;
        //     setKargo(_kargoB)
        // }
        let _kargo = { ...kargo };
        _kargo.pieceDetails[index][`${name}`] = val;

        setKargo(_kargo);

    }
    const orderInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

        let _order = { ...order };
        _order[`${name}`] = val;

        setOrder(_order);
    }
    const orderFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHideOrdered} />
            <Button label="Kaydet" icon="pi pi-times" className="p-button-text" onClick={() => setVisibleOrder(true)} />

        </>
    );

    const footerfooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHide} />
            <Button label="Kaydet" icon="pi pi-times" className="p-button-text" onClick={() => setVisible(true)} />

        </>
    );
    const footerKargo = (
        <>


            {
                parcaBilgiGiris == false && kayitVar && kargoSilDurum ? (
                    <>
                        <Button label="Kargo Bilgilerini Sil" icon="pi pi-trash" className="p-button-text p-button-danger mr-2" onClick={() => setVisibleKargoSil(true)} />
                        <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHideKargo} />
                        <Button label="Parça Bilgileri Gir" icon="pi pi-angle-double-right" className="p-button-text p-button-danger mr-2 " onClick={() => parcaGiris()} />

                    </>

                ) : parcaBilgiGiris == false ? (
                    <>
                        <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHideKargo} />
                        <Button label="Parça Bilgileri Gir" icon="pi pi-angle-double-right" className="p-button-text p-button-danger mr-2 " onClick={() => parcaGiris()} />
                    </>
                ) : geriDon ? (
                    <>
                        <Button label="Geri Dön" icon="pi pi-angle-double-left" className="p-button-text p-button-danger mr-2" onClick={() => parcaGirisGeri()} />
                        <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHideKargo} />
                        <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={() => setVisibleKargo(true)} />

                    </>
                ) : (
                    <>
                        <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={onHideKargo} />
                        <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={() => setVisibleKargo(true)} />
                    </>
                )
            }

        </>
    );

    const parcaGiris = () => {
        setSubmitted(true);

        if (kargo.pieceCount !== null) {
            setParcaBilgiGiris(true)
            // setParcaSayisi(parseInt(kargo.pieceCount))

        }

        setGeriDon(true)

        if (kayitVar !== true) {
            for (let i = 0; i < parcaSayisi; i++) {
                let s = i + 1;
                let parcaObje = {
                    volumetricWeight: "",
                    weight: "",
                    barcodeNumber: kargo?.integrationCode + 0 + s,
                    productNumber: orders[0]?.listOrderDetail[i]?.productId.toString(),
                    description: "",
                }
                kargo.pieceDetails.push(parcaObje)
            }
        }

    }

    const parcaGirisGeri = () => {
        setParcaBilgiGiris(false)
        setGeriDon(false)
        let sayi = kargo.pieceDetails.length
        if (kayitVar !== true) {
            for (let s = 0; sayi > s; s++) {
                kargo.pieceDetails.shift()
            }
        }

        // delete kargo.pieceDetails
    }
    const saveCustomer = () => {
        if (customer.orderedId !== null) {
            axios.put("https://api.horecaturquoise.com/Orders/UpdateOrdered", customer).then((res) => {
                if (res?.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kayıt Güncellendi', life: 3000 });
                    setListeYenile(!listeYenile)

                } else {
                    toast.current.show({ severity: 'warning', summary: 'BAŞARISIZ', detail: 'Bir Hata Oluştu', life: 3000 });

                }
            })
        }
        setCustomerDialog(false)
    }
    const saveKargo = () => {
        if (kargo.pieceDetails !== null) {
            axios.post("https://api.horecaturquoise.com/Kargo/GetArasSetorder", kargo).then((res) => {
                if (res?.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Kayıt Güncellendi', life: 3000 });
                    setListeYenile(!listeYenile)
                    setKargoDialog(false)
                    setGeriDon(false)
                    setParcaBilgiGiris(false)
                    setSubmitted(false)
                } else {
                    toast.current.show({ severity: 'warning', summary: 'BAŞARISIZ', detail: 'Bir Hata Oluştu', life: 3000 });
                    setListeYenile(!listeYenile)
                    setKargoDialog(false)
                    setGeriDon(false)
                    setParcaBilgiGiris(false)
                    setSubmitted(false)
                }
            })
        }
    }
    const saveOrder = () => {
        if (order.orderId !== null) {
            axios.put("https://api.horecaturquoise.com/Orders/UpdateOrder", order).then((res) => {
                if (res?.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Sipariş Durumu Güncellendi', life: 1000 });
                    setListeYenile(!listeYenile)

                } else {
                    toast.current.show({ severity: 'warning', summary: 'BAŞARISIZ1', detail: 'Bir Hata Oluştu', life: 1000 });

                }
            })
            // setCustomerDialog(false)
            setOrdersDialog(false)


        }

    }




    const barcodeGoster = (value, i) => {
        setVisibleEtiket(true)
        setEtiket(true)
        setSecilenEtiket(value)
        setSecilenEtiketIndex(i)
    }

    const onShipDate = (e) => {
        e.setHours(e.getHours() + 4)
        let kargoTarihi = e.toISOString();
        let orderss = { ...order }
        orderss.shipDate = kargoTarihi;
        setOrder(orderss)
    }

    return (
        <div className="datatable-rowexpansion-demo">
            <Toast ref={toast} position="top-center" />

            <Dialog
                header="Müşteri Bilgileri"
                visible={customerDialog}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={onHide}
                footer={footerfooter}
            >
                <div className="card">
                    <div className="p-fluid grid formgrid">

                        <div className="field col-12 md:col-6 ">
                            <span htmlFor="firstName">Müşteri Adı</span>
                            <InputText
                                id="firstName"
                                value={customer?.firstName}
                                onChange={(e) => customerInputChange(e, 'firstName')}


                            />
                        </div>
                        <div className="field col-12 md:col-6 ">
                            <span htmlFor="lastName">Müşteri Soyadı</span>
                            <InputText
                                id="lastName"
                                value={customer?.lastName}
                                onChange={(e) => customerInputChange(e, 'lastName')}


                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="address">Müşteri Adresi</span>
                            <InputText
                                id="address"
                                value={customer?.address}
                                onChange={(e) => customerInputChange(e, 'address')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="billingAddress">Fatura Adresi</span>
                            <InputText
                                id="billingAddress"
                                value={customer?.billingAddress}
                                onChange={(e) => customerInputChange(e, 'billingAddress')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="phone">Müşteri Telefonu</span>
                            <InputText
                                id="phone"
                                value={customer?.phone}
                                onChange={(e) => customerInputChange(e, 'phone')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="email">Müşteri E-Mail</span>
                            <InputText
                                id="email"
                                value={customer?.email}
                                onChange={(e) => customerInputChange(e, 'email')}

                            />
                        </div>
                        <ConfirmDialog visible={visible} onHide={() => setVisible(false)}
                            message="Güncelleme Yapmak İstediğinize Emin Misiniz ?"
                            header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={saveCustomer} reject={onHide} />
                    </div>
                </div>

            </Dialog>
            <Dialog
                header="Kargo Bilgileri"
                visible={kargoDialog}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={onHideKargo}
                footer={footerKargo}
            >
                <div className="card">
                    <div className="p-fluid grid formgrid">
                        {
                            parcaBilgiGiris == false ? (
                                <>
                                    {/* <div className="field col-12 md:col-6 ">
                                        <span htmlFor="shipDate">Kargo Tarihi</span>
                                        <Calendar
                                            id="shipDate"
                                            value={kargo?.shipDate}
                                            onChange={(e) => onShipDate(e.target.value)}
                                            dateFormat="dd/mm/yy"

                                        />

                                    </div> */}
                                    {/* <div className="field col-12 md:col-6 ">
                                        <span htmlFor="pieceCount">Kargo Parça Sayısı</span>
                                        <InputText
                                            id="pieceCount"
                                            value={kargo.pieceCount}
                                            onChange={(e) => kargoInputChange(e, 'pieceCount')}
                                            className={classNames({ 'p-invalid': submitted && !kargo.pieceCount })}
                                        />
                                        {submitted && !kargo.pieceCount && <small className="p-invalid">Parça Sayısı Zorunludur.</small>}

                                    </div> */}

                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="integrationCode">Entegrasyon Kodu</span>
                                        <InputText
                                            id="integrationCode"
                                            value={kargo.integrationCode}
                                            onChange={(e) => kargoInputChange(e, 'integrationCode')}
                                            maxLength={300}


                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="tradingWaybillNumber">İrsaliye Numarası</span>
                                        <InputText
                                            id="tradingWaybillNumber"
                                            value={kargo.tradingWaybillNumber}
                                            onChange={(e) => kargoInputChange(e, 'tradingWaybillNumber')}


                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="invoiceNumber">Fatura Numarası</span>
                                        <InputText
                                            id="invoiceNumber"
                                            value={kargo.invoiceNumber}
                                            onChange={(e) => kargoInputChange(e, 'invoiceNumber')}
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="pieceCount">Kargo Parça Sayısı</span>
                                        <InputText
                                            id="pieceCount"
                                            value={kargo.pieceCount}
                                            onChange={(e) => kargoInputChange(e, 'pieceCount')}
                                            className={classNames({ 'p-invalid': submitted && !kargo.pieceCount })}
                                        />
                                        {submitted && !kargo.pieceCount && <small className="p-invalid">Parça Sayısı Zorunludur.</small>}

                                    </div>

                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="weight">Kargo Kilo Bilgisi</span>
                                        <InputText
                                            id="weight"
                                            value={kargo.weight}
                                            onChange={(e) => kargoInputChange(e, 'weight')}

                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="volumetricWeight">Kargo Desi Bilgisi</span>
                                        <InputText
                                            id="volumetricWeight"
                                            value={kargo.volumetricWeight}
                                            onChange={(e) => kargoInputChange(e, 'volumetricWeight')}

                                        />
                                    </div>
                                    <div className="field col-12 md:col-12 ">
                                        <span htmlFor="receiverName">Alıcı Adı Soyadı</span>
                                        <InputText
                                            id="receiverName"
                                            value={kargo.receiverName}
                                            onChange={(e) => kargoInputChange(e, 'receiverName')}
                                            disabled
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12 ">
                                        <span htmlFor="receiverAddress">Alıcı Adresi</span>
                                        <InputText
                                            className="input"
                                            id="receiverAddress"
                                            value={kargo.receiverAddress}
                                            onChange={(e) => kargoInputChange(e, 'receiverAddress')}
                                            disabled
                                        />
                                    </div>
                                    <div className="field col-12 md:col-12 ">
                                        <span htmlFor="receiverPhone1">Alıcı Telefonu</span>
                                        <InputText
                                            id="receiverPhone1"
                                            value={kargo.receiverPhone1}
                                            onChange={(e) => kargoInputChange(e, 'receiverPhone1')}
                                            disabled

                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="receiverCityName">Alıcı İli</span>
                                        <InputText
                                            id="receiverCityName"
                                            value={kargo.receiverCityName}
                                            onChange={(e) => kargoInputChange(e, 'receiverCityName')}
                                            disabled

                                        />
                                    </div>
                                    <div className="field col-12 md:col-6 ">
                                        <span htmlFor="receiverTownName">Alıcı İlçesi</span>
                                        <InputText
                                            id="receiverTownName"
                                            value={kargo.receiverTownName}
                                            onChange={(e) => kargoInputChange(e, 'receiverTownName')}
                                            disabled

                                        />
                                    </div>
                                </>
                            ) : (

                                <>

                                    <ul>
                                        {

                                            // parcalar.map((deger, i)=><li key={i}>{deger.randomSayi}</li>)
                                            parcalar?.map((deger, i) =>
                                                <li key={i}>


                                                    <div className='grid p-fluid'>
                                                        <div className="col-12 md:col-6 ">
                                                            <span htmlFor="desi">Desi Giriniz</span>
                                                            <InputText
                                                                id="desi"
                                                                value={kargo?.pieceDetails[i]?.volumetricWeight}
                                                                onChange={(e) => parcaInputChange(e, 'volumetricWeight', i)}
                                                            />

                                                        </div>
                                                        <div className="col-12 md:col-6 ">
                                                            <span htmlFor="Kilo">Kilo Giriniz</span>

                                                            <InputText
                                                                id="Kilo"
                                                                value={kargo?.pieceDetails[i]?.weight}
                                                                onChange={(e) => parcaInputChange(e, 'weight', i)}

                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 ">
                                                            <span htmlFor="Barcode">Barcode Numarası</span>

                                                            <InputText
                                                                id="Barcode"
                                                                value={kargo?.pieceDetails[i]?.barcodeNumber}
                                                                onChange={(e) => parcaInputChange(e, 'barcodeNumber')}
                                                                disabled

                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 ">
                                                            <span htmlFor="urunId">Ürün Id</span>

                                                            <InputText
                                                                id="urunId"
                                                                value={kargo?.pieceDetails[i]?.productNumber}
                                                                onChange={(e) => parcaInputChange(e, 'productNumber')}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 ">
                                                            <span htmlFor="tanim">Tanım Giriniz</span>

                                                            <InputText
                                                                id="tanim"
                                                                value={kargo?.pieceDetails[i]?.description}
                                                                onChange={(e) => parcaInputChange(e, 'description', i)}
                                                            />
                                                        </div>
                                                        {
                                                            kargo?.pieceDetails[0]?.volumetricWeight.length > 0 && kargo?.pieceDetails[0]?.weight.length > 0 ? (
                                                                <>
                                                                    <div className="col-12 md:col-6 ">
                                                                        <span htmlFor="etiket">Etiket*</span>
                                                                        <Button
                                                                            id="etiket"
                                                                            icon="pi pi-qrcode"
                                                                            className="p-button-raised p-button-secondary mr-2"
                                                                            onClick={() => barcodeGoster(kargo, i)}
                                                                            label='Etiket Göster'
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )
                                                        }



                                                    </div>
                                                    <br />
                                                    <hr style={{
                                                        border: "3px  solid red",
                                                    }} />
                                                    <br />


                                                </li>
                                            )
                                        }

                                    </ul>

                                </>
                            )
                        }



                        {/* {
                            parcaBilgiGiris && parcaSayisi > 1 ? (
                               









                            ) : (
                                <>
                                </>
                            )
                        } */}

                        <ConfirmDialog visible={visibleKargo} onHide={() => setVisibleKargo(false)}
                            message="Kargo Bilgisi Kayıt İşlemi Yapmak İstediğinize Emin Misiniz ?"
                            header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={saveKargo} reject={onHideKargoConfirm} />
                    </div>
                    <ConfirmDialog visible={visibleKargoSil} onHide={() => setVisibleKargoSil(false)}
                        message="Kargo Bilgisini Silmek İstediğinize Emin Misiniz ?"
                        header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={kargoSil} reject={onHideKargoConfirm} />

                </div>

            </Dialog >
            <Dialog
                header="Sipariş Fişi"
                visible={ordersPrintDialog}
                modal
                style={{ width: '50vw', height: "65vw" }}
                onHide={() => onHideSipFis()}

            >
                <SiparisFisi siparis={seciliSiparis} />
            </Dialog>
            <Dialog
                header="ETİKET BİLGİLERİ"
                visible={visibleEtiket}
                modal
                style={{ width: '50vw' }}
                onHide={() => onHideEtiket()}

            >
                <div className="card" ref={componentRef} >
                    <div className='grid p-fluid'>
                        <div className="col-12 md:col-12 ">
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "8mm" }}>Gönderici&emsp;&emsp;: {egcTeknik.name}</span>
                            <br />
                            <hr style={{ border: "1px solid black" }} />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "8mm" }}>Alıcı Bilgileri </span>
                            <br />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>İsim&emsp;&ensp;&ensp;&ensp;&emsp;&emsp;&emsp;: {kargo?.receiverName}</span>
                            <br />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>Telefon&emsp;&emsp;&emsp;&emsp;: {kargo?.receiverPhone1}</span>
                            <br />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>Adres&ensp; &emsp;&emsp;&emsp;&emsp;: {kargo?.receiverAddress}</span>
                            <hr style={{ border: "1px solid black" }} />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "9mm" }}>Standart Kargo &emsp; &emsp;</span>
                            <hr style={{ border: "1px solid black" }} />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>Entegrasyon No : {kargo?.integrationCode}</span>
                            <br />
                            <span>&emsp;&emsp;<Barcode displayValue={false} value={kargo.integrationCode} /></span>
                            <hr style={{ border: "1px solid black" }} />
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>Paket Barkod No : {kargo?.pieceDetails[secilenEtiketIndex]?.barcodeNumber}</span>
                            <br />
                            <span>&emsp;&emsp;<Barcode displayValue={false} value={kargo?.pieceDetails[secilenEtiketIndex]?.barcodeNumber} /></span>
                            <span style={{ color: "black", fontWeight: "bold", fontSize: "7mm" }}>&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Paket 1/{secilenEtiketIndex + 1}</span>


                        </div>


                    </div>
                </div>
                <div className='card'>
                    <div className="grid p-fluid">
                        <div className="field col-12 md:col-4" >
                            <Button
                                onClick={handlePrint}
                                icon="pi pi-print"
                                label='Etiket Yazdır'
                            />

                        </div>
                        <div className="field col-12 md:col-4">

                            <Pdf targetRef={componentRef} filename="siparişFişi.pdf">
                                {({ toPdf }) => (
                                    <Button
                                        icon="pi pi-download"
                                        label='PDF İndir'
                                        className="p-button-success mr-2"
                                        onClick={toPdf}>

                                    </Button>
                                )}
                            </Pdf>
                        </div>
                        <div className="field col-12 md:col-4">
                            <Button
                                label="Ekranı Kapat"
                                icon="pi pi-sign-out"
                                onClick={onHideEtiket} />

                        </div>

                    </div>
                </div>


            </Dialog>

            <Dialog
                header="Sipariş Bilgileri"
                visible={ordersDialog}
                modal
                style={{ width: '50vw' }}
                onHide={() => onHideOrdered()}
                footer={orderFooter}

            >

                <div className="card">
                    <div className="p-fluid grid formgrid">
                        {
                            kargoVrlmsMi ?
                                (
                                    <>
                                        <div className="field col-12 md:col-12 ">
                                            <span htmlFor="shipStatus">Sipariş Durumu</span>
                                            <Dropdown
                                                id="shipStatus"
                                                value={order?.shipStatus == 1 ? "Onaylandı" :
                                                    order?.shipStatus == 2 ? "Hazırlanıyor" :
                                                        order?.shipStatus == 3 ? "Kargolandı" :
                                                            order?.shipStatus == 4 ? "Teslim Edildi" :
                                                                order?.shipStatus == 5 ? "Sipariş İptal" :
                                                                    order?.shipStatus == 6 ? "İade" : "Diğer"}
                                                options={sipStat}
                                                onChange={(e) => onShipStats(e)}
                                                optionLabel="name"
                                                optionValue="code"
                                                placeholder="Sipariş Durumu Seçiniz"
                                            />

                                        </div>
                                        {
                                            order.shipStatus == 3 ?
                                                (
                                                    <>
                                                        <div className="field col-12 md:col-12 ">
                                                            <span htmlFor="lastName">Kargo Numarası</span>
                                                            <InputText
                                                                id="lastName"
                                                                maxLength={50}
                                                                value={order.shipNumber}
                                                                onChange={(e) => orderInputChange(e, 'shipNumber')}


                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )

                                        }
                                    </>
                                ) : (
                                    <>
                                        <div className="field col-12 md:col-12 ">
                                            <span htmlFor="shipDate">Kargo Tarihi </span>
                                            <Calendar
                                                id="shipDate"
                                                value={order?.shipDate}
                                                onChange={(e) => onShipDate(e.target.value)}
                                                dateFormat="dd/mm/yy"

                                            />

                                        </div>
                                        <div className="field col-12 md:col-12 ">
                                            <span htmlFor="shipStatus">Sipariş Durumu</span>
                                            <Dropdown
                                                id="shipStatus"
                                                value={order?.shipStatus == 1 ? "Onaylandı" :
                                                    order?.shipStatus == 2 ? "Hazırlanıyor" :
                                                        order?.shipStatus == 3 ? "Kargolandı" :
                                                            order?.shipStatus == 4 ? "Teslim Edildi" :
                                                                order?.shipStatus == 5 ? "Sipariş İptal" :
                                                                    order?.shipStatus == 6 ? "İade" : "Diğer"}
                                                options={sipStat}
                                                onChange={(e) => onShipStats(e)}
                                                optionLabel="name"
                                                optionValue="code"
                                                placeholder="Sipariş Durumu Seçiniz"
                                            />

                                        </div>
                                        {
                                            order.shipStatus == 3 || order?.shipDate !== null ?
                                                (
                                                    <>
                                                        <div className="field col-12 md:col-12 ">
                                                            <span htmlFor="lastName">Kargo Numarası</span>
                                                            <InputText
                                                                id="lastName"
                                                                maxLength={50}
                                                                value={order.shipNumber}
                                                                onChange={(e) => orderInputChange(e, 'shipNumber')}


                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )

                                        }

                                    </>
                                )
                        }
                        {/* <div className="field col-12 md:col-12 ">
                            <span htmlFor="shipDate">Kargo Tarihi</span>
                            <Calendar
                                id="shipDate"
                                value={order?.shipDate}
                                onChange={(e) => onShipDate(e.target.value)}
                                dateFormat="dd/mm/yy"

                            />

                        </div> */}

                        <ConfirmDialog visible={visibleOrder} onHide={() => setVisibleOrder(false)}
                            message="Güncelleme Yapmak İstediğinize Emin Misiniz ?"
                            header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={saveOrder} reject={onHideOrdered} />

                    </div>
                </div>

            </Dialog>


            <Dialog
                header="Sipariş Tanımlama"
                visible={false}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={onHide}
                footer={footerfooter}
            >
                <div className="card">
                    <div className="p-fluid grid formgrid">

                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="firstName">Müşteri Adı</span>
                            <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Select a Country"
                                ilter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
                        </div>
                        <div className="field col-12 md:col-6 ">
                            <span htmlFor="lastName">Müşteri Soyadı</span>
                            <InputText
                                id="lastName"
                                value={customer?.lastName}
                                onChange={(e) => customerInputChange(e, 'lastName')}


                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="address">Müşteri Adresi</span>
                            <InputText
                                id="address"
                                value={customer?.address}
                                onChange={(e) => customerInputChange(e, 'address')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="billingAddress">Fatura Adresi</span>
                            <InputText
                                id="billingAddress"
                                value={customer?.billingAddress}
                                onChange={(e) => customerInputChange(e, 'billingAddress')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="phone">Müşteri Telefonu</span>
                            <InputText
                                id="phone"
                                value={customer?.phone}
                                onChange={(e) => customerInputChange(e, 'phone')}
                            />
                        </div>
                        <div className="field col-12 md:col-12 ">
                            <span htmlFor="email">Müşteri E-Mail</span>
                            <InputText
                                id="email"
                                value={customer?.email}
                                onChange={(e) => customerInputChange(e, 'email')}

                            />
                        </div>
                        <ConfirmDialog visible={visible} onHide={() => setVisible(false)}
                            message="Güncelleme Yapmak İstediğinize Emin Misiniz ?"
                            header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={saveCustomer} reject={onHide} />
                    </div>
                </div>

            </Dialog>

            <div className="card">
                {/* <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar> */}

                <DataTable
                    showGridlines value={orders} size='small' expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={rowExpansionTemplate} dataKey="orderNumber" header={header}>
                    <Column expander={allowExpansion} style={{ width: '3em' }} />
                    <Column field="orderNumber" header="S.No" style={{ width: '3em' }} body={(e) => e.orderId} sortable />

                    <Column field="firstName" body={(e) => (e.listOrderDetail[0].ordered.firstName + " " + e.listOrderDetail[0].ordered.lastName).toUpperCase()} header="Adı Soyadı" />
                    <Column field="orderDate" header="Sipariş Tarihi" body={orderDate} sortable />
                    <Column field="paid" header="Tutarı" sortable body={paidBody} />
                    {/* <Column field="transactStatus" header="İşlem Durumu" body={transactStatusBody} />
                    <Column  field="errmsgBody" header="Banka Mesajı" sortable body={errmsgBody} /> */}
                    <Column field="deliveredOffice" style={{ width: '3em' }} header="Ofs.Teslim" body={ofisTeslimBody} />
                    <Column field="shipDate" header="Kargo Tarihi" sortable body={shipDate} />
                    <Column field="isActive" header="Onay Durumu" body={isActive} />
                    <Column field="shipStatus" header="Sipariş Durumu" body={shipStatus} />
                    <Column header="İşlem Menüsü" body={actionBodyTemplate} />
                </DataTable>


            </div>
        </div >
    );
}

const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <div className="my-2">
                <Button label="Manuel Sipariş Tanımla" icon="pi pi-wallet" className="p-button-warning mr-2" />
                {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </div>
        </React.Fragment>
    )
}

const countries = [
    { name: 'Australia', code: 'AU' },
    { name: 'Brazil', code: 'BR' },
    { name: 'China', code: 'CN' },
    { name: 'Egypt', code: 'EG' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'India', code: 'IN' },
    { name: 'Japan', code: 'JP' },
    { name: 'Spain', code: 'ES' },
    { name: 'United States', code: 'US' }
];

const selectedCountryTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const countryOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
            <div>{option.name}</div>
        </div>
    );
};







const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname == nextProps.location.pathname;
};

export default React.memo(OrderPages, comparisonFn)
