import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import DosyaYukleme from '../components/DosyaYukle';
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { Image } from 'primereact/image';
import DosyaYukleBlog from '../components/DosyaYukleBlog';

const Blog = () => {
    let emptyBlog = {
        blogID: -1,
        title: '',
        content: "",
        picture: "",
        publishDate: null,
        categoryID: null,

    };

    const evetHayir = [
        {
            code: "EVET",
            descrp: "EVET"
        },
        {
            code: "HAYIR",
            descrp: "HAYIR"
        }
    ]
    const [blogDialog, setBlogDialog] = useState(false);
    const [blog, setBlog] = useState(emptyBlog);
    const [selectedBlogs, setSelectedBlogs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [blogList, setBlogList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [editBlogs, setEditBlogs] = useState(false);
    const [kayitSonuc, setKayitSonuc] = useState(false);
    const [blogID, setBlogID] = useState(null);
    const [yenile, setYenile] = useState(null);
    const [hideDiv, setHideDiv] = useState(false);


    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Blog/GetBlogCategory").then((response) => {
            setCategoryList(response?.data.data)
        })
    }, [])

    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Blog/GetPost").then((response) => {

            setBlogList(response?.data?.data)
        })
    }, [yenile])




    const openNew = () => {
        setBlog(emptyBlog);
        setEditBlogs(false)
        setSubmitted(false);
        setBlogDialog(true);
        setKayitSonuc(false)

    }

    const hideDialog = () => {
        setKayitSonuc(false)
        setEditBlogs(false)
        setSubmitted(false);
        setBlogDialog(false);
    }


    const saveBlog = () => {
        let crrntordr = { ...blog };
        crrntordr.currentOrder = false;
        setBlog(crrntordr)
        setSubmitted(true);
        if (blog.title !== null) {
           

            if (blog.blogID > -1) {
                const updateBlog = {
                    blogID: blog.blogID,
                    title: blog.title,
                    content: blog.content,
                    picture: blog.picture,
                    publishDate: new Date(),
                    categoryID: blog.categoryID,
                }
                console.log("Güncelleme",updateBlog)              
                axios.post("https://api.horecaturquoise.com/Blog/UpdatePost", updateBlog).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Güncellendi', life: 1000 });
                    setYenile(!yenile)

                })
            }
            else {
                const addBlog = {
                    title: blog.title,
                    content: blog.content,
                    picture: blog.picture,
                    publishDate: new Date(),
                    categoryID: blog.categoryID,
                }
                console.log("Ekleme",addBlog)            
                axios.post("https://api.horecaturquoise.com/Blog/SetPost", addBlog).then((response) => {
                    console.log(response)
                    // setBlogList(response?.data?.data.blogId)
                    if (response.status == 200) {
                        setKayitSonuc(true)
                        setBlogID(response?.data?.data?.blogId)
                        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Eklend,', life: 1000 });
                        setYenile(!yenile)

                    }
                })
            }

        }
    }

    const editBlog = (blog) => {
        setBlog({ ...blog });
        setBlogDialog(true);
        setBlogID(blog.blogId)
        setKayitSonuc(true)
        setEditBlogs(true)


    }






    const onCategoryChange = (e, name) => {
        let _blog = { ...blog };
        _blog[name] = e.value;
        setBlog(_blog);
    }

    const onInputChange = (e, name) => {
        var val = (e.target && e.target.value) || '';
        if(name=='content'){
            val=e.htmlValue;
        }

        let price = val;
        let _blog = { ...blog };
        _blog[`${name}`] = price;
        setBlog(_blog);
       
        
    }




    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <Image src={rowData.picture} preview className="shadow-1 round" style={{ borderRadius: '27px' }} width="35" />
            </>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Post Ekle" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedBlogs || !selectedBlogs.length} /> */}
                </div>
            </React.Fragment>
        )
    }












    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ cursor: "pointer", background: "aliceblue", padding: "11px 0" }} onClick={() => editBlog(rowData)}>
                <i className="pi pi-pencil" />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Blog İşlemleri</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );

    const blogDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveBlog} />
        </>
    );







    const textEditor = (options) => {
        return <InputNumber inputId="locale-us" mode="decimal" locale="en-US" value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    }


    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <Toolbar className="mb-1" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable
                        ref={dt}
                        value={blogList}
                        editMode="row"
                        selection={selectedBlogs}
                        showGridlines
                        onSelectionChange={(e) => setSelectedBlogs(e.value)}
                        dataKey="blogID"
                        paginator
                        rows={9}
                        rowsPerPageOptions={[20, 25, 30]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen  {first} ile {last} arasında, toplam sayısı : {totalRecords} "
                        globalFilter={globalFilter} size='small' emptyMessage="bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="blogID" header="Id" style={{ minWidth: '5rem' }} body={(e) => e.blogID}></Column>
                        <Column header="Resim" style={{ minWidth: '2rem' }} body={imageBodyTemplate}></Column>
                        <Column field="title" header="Başık" style={{ minWidth: '20rem' }} body={(e) => e.title}></Column>
                        {/* <Column field="content" header="İçerik" style={{ minWidth: '20rem' }} body={(e) => e.content}></Column> */}
                        <Column field="publishDate" header="Tarih" style={{ minWidth: '20rem' }} body={(e) => e.publishDate}></Column>
                        <Column header="Güncelle" style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} ></Column>

                    </DataTable>

                    <Dialog visible={blogDialog} style={{ width: '1400px', height: '1000px' }} header="Post Hazırlama" modal className="p-fluid" footer={blogDialogFooter} onHide={hideDialog}>
                        {blog.image && <img src={`assets/demo/images/blog/${blog.image}`} alt={blog.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="card">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-12 md:col-12 ">
                                    <span htmlFor="name">Başlık</span>
                                    <InputText
                                        id="name"
                                        maxLength={300}
                                        value={blog.title}
                                        onChange={(e) => onInputChange(e, 'title')}
                                        required
                                        autoFocus
                                        // disabled={kayitSonuc}
                                        className={classNames({ 'p-invalid': submitted && !blog.title })} />
                                    {submitted && !blog.title && <small className="p-invalid"> Zorunludur.</small>}
                                </div>


                                <div className="field col-12 md:col-12">
                                    <span htmlFor="content">Açıklama</span>
                                    <Editor
                                        style={{ height: '500px' }}
                                        maxLength={1000}
                                        value={blog.content}
                                        onTextChange={(e) => onInputChange(e, 'content')} />
                                </div>

                                <div className="field col-12 md:col-12 ">
                                    <span htmlFor="categoryID">Blog Kategorisi</span>

                                    <Dropdown
                                        value={blog.categoryID}
                                        options={categoryList}
                                        onChange={(e) => onCategoryChange(e, "categoryID")}
                                        optionLabel="name"
                                        optionValue="categoryID"
                                        placeholder="Ürün Kategorisi Seçiniz"
                                    />
                                </div>

                                {kayitSonuc !== false ? (
                                        <>
                                            <div className="field col-12 md:col-12">
                                                <span htmlFor="indirim-tutar">Resim Yükleme/Silme</span>

                                                <DosyaYukleBlog
                                                    product={blog}
                                                    productId={blog.blogID} // ilgili kaydın id numarası
                                                    dosyaYukle={true} // dosya yükle ekranı açık olsun
                                                    listeDosyaSil={true} // dosya silme butonu açık olsun
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}


                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Blog, comparisonFn)