import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';



const Products = () => {
    let emptyProduct = {
        productId: -1,
        productName: '',
        productDescription: "",
        supplierId: 1,
        categoryId: null,
        unitPrice: 0,
        picture: null,
        sizeId: 1,
        colorId: 1,
        discount: 0,
        discountAvailable: false,
        currentOrder: true,
        quantityPerUnit: 0,
        purchasePrice: 0,
        basePrice: 0,
        productDay: null,
        campaigns: null,
        stock_code: null,
        barcode: null,
        currentOrder: false,
        process_id: 0,
        status: true

    };

    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productList, setProductList] = useState(null);
    const [yenile, setYenile] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Product/GetProductList").then((response) => {
         let product=   response?.data?.data.sort(function(a, b) {
                return b.campaigns - a.campaigns;
              });

            setProductList(product)
        })
    }, [yenile])




    const campaignsChange = (rowData) => {
        console.log(rowData.productId);
        axios.post(`https://api.horecaturquoise.com/Product/ProductOutlet?productId=${rowData.productId}`).then((response) => {
            console.log("Kampanya ", response);
            if (response.status == 200) {
                setYenile(!yenile)
            }
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Outlet Durumu Başarılı Şekilde Güncellendi', life: 2000 });
        })
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Image</span> */}
                <img src={rowData.images[0]?.name} className="shadow-1 round" style={{ borderRadius: '27px' }} width="50" />
            </>
        )
    }


    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    }

    const header2 = renderHeader();




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.campaigns != 2 ?
                    <Button icon="pi pi-check-circle" label="Ekle" className="p-button-success mr-1" onClick={() => campaignsChange(rowData)} /> :
                    <Button icon="pi pi-minus-circle" label="Çıkar"  className="p-button-danger mr-1" onClick={() => campaignsChange(rowData)} />}

            </div>

        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">OUTLET ÜRÜN TANIMLAMA</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );




    const textEditor = (options) => {
        console.log("options", options);
        return <InputNumber inputId="locale-us" mode="decimal" locale="en-US" value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    }

    const onRowEditComplete1 = (e, rowData) => {
        console.log("e nedir, ", e);
        let _products2 = { ...rowData };
        let { newData } = e;
        // console.log("index ne ? ", index);

        _products2 = newData;
        console.log("_products2", _products2);
        setProduct(_products2);
        axios.post("https://api.horecaturquoise.com/Product/UpdateProduct", _products2).then((response) => {
            // setProductList(response?.data?.data)7
            console.log("update oldu mu ", response);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ürün Başarılı Şekilde Güncellendi', life: 1000 });
            setYenile(!yenile)

        })
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <DataTable
                        ref={dt}
                        value={productList}
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        selection={selectedProducts}
                        showGridlines
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        size='small'
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen ürün {first} ile {last} arasında, toplam ürün sayısı : {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Ürün bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="productId" header="Ürün Id" style={{ minWidth: '5rem' }} body={(e) => e.productId}></Column>
                        <Column header="Resim" style={{ minWidth: '3rem' }} body={imageBodyTemplate}></Column>
                        <Column field="productName" header="Ürün Adı" style={{ minWidth: '25rem' }} body={(e) => e.productName}></Column>
                        <Column field="unitPrice" header="Satış Fiyat" editor={(options) => textEditor(options)} style={{ minWidth: '5rem' }} sortable body={(e) => e.unitPrice}></Column>
                        <Column field="stok_code" header="Stok Kodu" editor={(options) => textEditor(options)} style={{ minWidth: '5rem' }} sortable body={(e) => e.stock_code}></Column>
                        <Column field="barcode" header="Barcode" style={{ minWidth: '5rem' }} body={(e) => e.barcode}></Column>
                        <Column header="Outlet Ürünğ Seç" style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} ></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Products, comparisonFn)