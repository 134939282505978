import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import axios from 'axios';



const Marka = () => {
    let emptyproperties = {
        supplierId: null,
        companyName: '',
        contactFname: null,
    };



    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [altCate, setAltCate] = useState(false);
    const [yenile, setYenile] = useState(false);
    const [kayitSonuc, setKayitSonuc] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [visible, setVisible] = useState(false);
    const [silindi, setSilindi] = useState(false);
    const [dosyaListesi, setDosyaListesi] = React.useState([]);
    const [dokumanId, setDokumanId] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);



    const [properties, setProperties] = useState();
    const [property, setProperty] = useState(emptyproperties);;

    useEffect(() => {
        axios.get("https://api.horecaturquoise.com/Property/GetMarka").then((response) => {
            setProperties(response?.data?.data)
            
        })
    }, [yenile])






    const openNew = () => {
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setAltCate(false)
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...property };
        _product[`${name}`] = val;
        setProperty(_product);
    }




    const saveProperties = () => {
        setSubmitted(true);
        if (property.companyName !== null && property.companyName.length > 0) {

            let thisProperty = {
                companyName: property.companyName,

            }

            axios.post("https://api.horecaturquoise.com/Property/SetMarka", thisProperty).then((response) => {
                if (response.status == 200) {
                    toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Özelik Başlığı  Eklendi', life: 3000 });                   
                    setProductDialog(false)
                    setKayitSonuc(true)
                    setYenile(!yenile)
                }
            })
        }
    }




    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Marka Tanımlama" icon="pi pi-plus" className=" p-button-raised p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
                <div className="my-2">
                    {/* <Button label="Alt Kategori Ekle" icon="pi pi-plus" className="p-button-raised p-button-success p-button-text mr-2" onClick={openAltNew} /> */}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>



            </React.Fragment>
        )
    }





    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Markalar</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" disabled={property?.companyName?.length <= 0} icon="pi pi-check" className="p-button-text" onClick={saveProperties} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable
                        ref={dt}
                        showGridlines
                        value={properties}
                        dataKey="supplierId"
                        paginator
                        size='small'
                        rows={30}
                        rowsPerPageOptions={[30, 60, 90]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen Özellik {first} ile {last} arasında, toplam özellik sayısı {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Kategori bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="supplierId" header="Özellik Id" body={(e) => e.supplierId} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="companyName" header="Adı" body={(e) => e.companyName} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '950px' }} header="Tanımla" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Marka Başlığı</label>
                            <InputText id="name" value={property.companyName} onChange={(e) => onInputChange(e, 'companyName')} required className={classNames({ 'p-invalid': submitted && !property.companyName })} />
                            {submitted && !property.companyName && <small className="p-invalid">Adı Zorunludur.</small>}
                        </div>

                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Marka, comparisonFn);